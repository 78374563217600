import FormRouter from 'client/pages/profile/components/FormRouter';
import React from 'react';
import { Box, Heading, Text } from '@indeed/ifl-components';
import { formatDateWithDayOfWeek } from 'client/utils/dayjs';

type PageContentProps = {
    task: ComplianceTask;
};

const PageContent = ({ task }: PageContentProps): JSX.Element => {
    switch (task.status) {
        case 'NOT_COMPLETED':
            return <FormRouter task={task} />;
        case 'COMPLETED':
            return (
                <Box>
                    <Heading level={4}>Profile</Heading>
                    <Text>
                        Your profile was completed on {formatDateWithDayOfWeek(task.completedDate)}.
                    </Text>
                </Box>
            );
        default:
            throw Error(`Invalid status for profile: ${task.status}`);
    }
};

export default PageContent;
