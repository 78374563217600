import BackButton from 'client/components/BackButton';
import DocumentListPage from 'client/pages/documentlist';
import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { Box } from '@indeed/ifl-components';
import { LoadingPage } from 'client/components/Loading';
import { RequiresProfilePageContent } from 'client/components/RequiresTaskPageContent/RequiresTaskPageContent';
import { UkDocumentsTaskTrackingEvents } from 'client/pages/ukdocuments/UkDocuments.tracking';
import {
    getMatchingWorkerDocument,
    getTaskDocuments,
    getUkDocumentsMetadata,
} from 'client/utils/task';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useTaskDisabledReason } from 'client/hooks/useTaskDisabledReason';
import { useTasks } from 'client/contexts/TasksContext';
import { useToast } from 'client/contexts/ToastContext';
import { useUpdateTaskStatusMutation } from 'client/hooks/graphql';

type PageContentProps = {
    task: ComplianceTask;
};

const isExpiredOrNull = (completedDate: string): boolean => {
    return !completedDate || dayjs() > dayjs(completedDate).add(1, 'year');
};

const PageContent = ({ task }: PageContentProps): JSX.Element => {
    const disabledReason = useTaskDisabledReason(task);
    const ukDocuments = getUkDocumentsMetadata(task);
    const { updateTask } = useTasks();
    const { pushToast } = useToast();
    const [callUpdateTaskStatusMutation, { loading: updateTaskStatusLoading }] =
        useUpdateTaskStatusMutation();

    /**
     * Iterates through all of the tasks and completes the task when there are no more templates without a corresponding
     * worker document. Done here rather in the document display page so that the user can "resubmit" the task
     * completion in case of an error by refreshing.
     */
    useEffect(() => {
        if (task && !disabledReason && task.status !== 'COMPLETED') {
            const { templates, workerDocuments } = getTaskDocuments(task);
            if (
                !templates.find(
                    (template) => !getMatchingWorkerDocument(template, workerDocuments)
                ) &&
                !isExpiredOrNull(ukDocuments?.overseasQuestionnaireCompletedDate) &&
                !isExpiredOrNull(ukDocuments?.healthQuestionnaireCompletedDate)
            ) {
                callUpdateTaskStatusMutation({
                    variables: { slug: task.slug, status: 'COMPLETED' },
                })
                    .then(({ data: taskData }) => {
                        const taskResult = taskData?.updateTask?.result;
                        if (taskResult) {
                            updateTask(taskResult);
                        } else {
                            reportErrorToThirdPartyTools(
                                new Error(
                                    `Could not retrieve result from updateTask mutation given input: taskSlug:${task.slug}, taskStatus:COMPLETED`
                                ),
                                'UkDocumentsPage',
                                'checkAndPossiblyCompleteTask'
                            );
                            pushToast(
                                'There was an error while retrieving the response from submission, but the data may have saved. Refresh the page and try again.',
                                { status: 'warning' }
                            );
                        }
                    })
                    .catch((apolloError) => {
                        reportErrorToThirdPartyTools(
                            apolloError,
                            'UkDocumentsPage',
                            'checkAndPossiblyCompleteTask'
                        );
                        pushToast(
                            'There was an error while attempting to submit the information, please try again.',
                            { status: 'critical' }
                        );
                    });
            }
        }
    }, [task]);

    if (disabledReason === 'INCOMPLETE_PROFILE') {
        return (
            <Box>
                <BackButton trackingEvent={UkDocumentsTaskTrackingEvents.backBtnClicked} />
                <RequiresProfilePageContent
                    task={task}
                    taskName="onboarding documents"
                    heading="Onboarding documents"
                />
            </Box>
        );
    }

    const documentTasksForms = useMemo(() => {
        const pathWithTask = `/uk-documents/${task.slug}`;

        const healthForm = {
            title: 'Health Questionnaire',
            formPath: `${pathWithTask}/health-questionnaire`,
            completedDate: ukDocuments?.healthQuestionnaireCompletedDate,
        };

        const overseasForm = {
            title: 'Overseas Questionnaire',
            formPath: `${pathWithTask}/overseas-questionnaire`,
            completedDate: ukDocuments?.overseasQuestionnaireCompletedDate,
        };

        return {
            tasks: [task],
            forms: [healthForm, overseasForm],
        };
    }, [
        task,
        ukDocuments?.healthQuestionnaireCompletedDate,
        ukDocuments?.overseasQuestionnaireCompletedDate,
    ]);

    if (updateTaskStatusLoading) {
        return <LoadingPage />;
    }

    return <DocumentListPage pageTitle="Onboarding documents" {...documentTasksForms} />;
};

export default PageContent;
