import InputMask from 'react-input-mask';
import React, { useState } from 'react';
import {
    CheckboxFormField,
    CheckboxFormFieldProps,
    InputFormField,
    InputProps,
    RadioFormField,
    RadioFormFieldProps,
    SelectFormField,
    SelectProps,
} from '@indeed/ifl-components';
import { ExtendableObject } from '@indeed/ifl-components/src/types/index';
import { Field, FieldProps } from 'formik';

export * from './FileUpload';
/*
 NOTE: All these field components must be called within Formik components
 */
interface TextFieldProps {
    name: string;
    label?: string;
    helperText?: string;
    inputProps?: InputProps & ExtendableObject;
    error?: string;
    errorTextId?: string;
}

export const TextField = ({
    name,
    label,
    helperText,
    inputProps = {},
    error,
    errorTextId = `${name}-errorText`,
}: TextFieldProps): JSX.Element => {
    return (
        <Field name={name}>
            {({ field, meta }: FieldProps<string>) => {
                const errors = error || (meta.touched ? (meta.error as string) : undefined);
                return (
                    <InputFormField
                        showRequiredIndicator={inputProps?.required}
                        label={label}
                        helperText={helperText}
                        errorText={errors}
                        invalid={!!errors}
                        inputProps={{
                            id: `${name}-field`,
                            ...field,
                            ...inputProps,
                        }}
                        errorTextId={errorTextId}
                    />
                );
            }}
        </Field>
    );
};

interface SelectFieldProps {
    name: string;
    label?: string;
    options: SelectFormatType[];
    selectProps?: SelectProps;
    error?: string;
    errorTextId?: string;
    placeholder?: string;
}

export const SelectField = ({
    name,
    label,
    options,
    selectProps = {},
    error,
    errorTextId = `${name}-errorText`,
    placeholder,
}: SelectFieldProps): JSX.Element => {
    return (
        <Field name={name}>
            {({ field, meta }: FieldProps<string>) => {
                const errors = error || (meta.touched ? (meta.error as string) : undefined);
                return (
                    <SelectFormField
                        showRequiredIndicator={selectProps?.required}
                        label={label}
                        options={options}
                        errorText={errors}
                        invalid={!!errors}
                        selectProps={{
                            id: `${name}-field`,
                            ...field,
                            ...selectProps,
                        }}
                        aria-placeholder={placeholder}
                        errorTextId={errorTextId}
                    />
                );
            }}
        </Field>
    );
};

interface RadioFieldProps {
    name: string;
    label?: string;
    helperText?: string;
    items: SelectFormatType[];
    radioProps?: Omit<RadioFormFieldProps, 'items'>;
    errorTextId?: string;
}

export const RadioField = ({
    name,
    label,
    helperText,
    items,
    radioProps = {},
    errorTextId = `${name}-errorText`,
}: RadioFieldProps): JSX.Element => {
    return (
        <Field name={name}>
            {({ field, meta }: FieldProps<string>) => {
                const { onChange, onBlur, value } = field;
                const errors = meta.touched ? (meta.error as string) : undefined;
                return (
                    <RadioFormField
                        id={`${name}-field`}
                        name={name}
                        label={label}
                        helperText={helperText}
                        items={items.map((item) => ({
                            ...item,
                            onChange,
                            onBlur,
                            checked: value === item.value,
                        }))}
                        errorTextId={errorTextId}
                        errorText={errors}
                        invalid={!!errors}
                        {...radioProps}
                    />
                );
            }}
        </Field>
    );
};

export type MaskedTextFieldProps = TextFieldProps & {
    mask: string;
    removeCharRegex?: string;
    hideOnBlur?: boolean;
    showInitialValue?: boolean;
    showRequiredIndicator?: boolean;
    alwaysShowMask?: boolean;
};

export const MaskedTextField = ({
    name,
    label,
    inputProps = {},
    mask,
    hideOnBlur,
    removeCharRegex,
    errorTextId = `${name}-errorText`,
    showInitialValue,
    showRequiredIndicator,
}: MaskedTextFieldProps): JSX.Element => {
    const regex = new RegExp(removeCharRegex || '', 'g');
    return (
        <Field name={name}>
            {({ field, meta }: FieldProps<string>) => {
                const errors = meta.touched ? (meta.error as string) : undefined;
                const [isFocused, setIsFocused] = useState(false);
                return (
                    <InputMask
                        defaultValue={field.value}
                        mask={mask}
                        maskPlaceholder={null}
                        onBlur={(event: React.FocusEvent) => {
                            setIsFocused(false);
                            field.onBlur(event);
                        }}
                        onFocus={() => setIsFocused(true)}
                        onChange={(event: React.ChangeEvent) => {
                            const input = event.target as HTMLInputElement;
                            input.value = input.value.replace(regex, '');
                            field.onChange(event);
                        }}
                    >
                        <InputFormField
                            showRequiredIndicator={
                                showRequiredIndicator != null
                                    ? showRequiredIndicator
                                    : inputProps?.required
                            }
                            label={label}
                            errorText={errors}
                            invalid={!!errors}
                            errorTextId={errorTextId}
                            inputProps={{
                                id: `${name}-field`,
                                type: !isFocused && hideOnBlur ? 'password' : 'text',
                                ...inputProps,
                                name: field.name,
                                ...(showInitialValue ? { value: field.value } : {}),
                            }}
                        />
                    </InputMask>
                );
            }}
        </Field>
    );
};

export interface CheckboxFieldProps {
    name: string;
    label?: string;
    items: CheckboxFormatType[];
    checkboxProps?: Omit<CheckboxFormFieldProps, 'items'>;
    errorTextId?: string;
}

export const CheckboxField = ({
    name,
    label,
    items,
    checkboxProps = {},
    errorTextId = `${name}-errorText`,
}: CheckboxFieldProps): JSX.Element => {
    return (
        <Field name={name}>
            {({ field, meta }: FieldProps<string>) => {
                const { onChange, onBlur, value } = field;
                const errors = meta.touched ? (meta.error as string) : undefined;
                return (
                    <CheckboxFormField
                        id={`${name}-field`}
                        name={name}
                        label={label}
                        items={items.map((item) => ({
                            ...item,
                            onChange,
                            onBlur,
                            checked: value.includes(item.value),
                        }))}
                        errorTextId={errorTextId}
                        errorText={errors}
                        invalid={!!errors}
                        {...checkboxProps}
                    />
                );
            }}
        </Field>
    );
};
