import * as FormikFields from 'client/components/FormikFields';
import FormRow from 'client/components/FormRow';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Col } from '@indeed/itmp-components';
import { UsSsnAppliedFor, UsSsnAppliedForProps } from './UsSsnAppliedFor/UsSsnAppliedFor';
import { UsSsnParagraph } from './UsSsnParagraph/UsSsnParagraph';
import { getStatusIncludesDuplicatedSsn } from './UsSsnDuplicatedAlert/UsSsnDuplicatedAlert.utils';

const SsnLegalText = (): JSX.Element => {
    return (
        <>
            <UsSsnParagraph>
                I authorize Indeed Flex to collect and process my Social Security Number (SSN) for
                the following purposes: <br />
                Payroll / Benefits
            </UsSsnParagraph>
            <UsSsnParagraph sx={{ marginBlockEnd: 0 }}>
                I understand that my SSN will only be used for the above purposes and that I may
                revoke my consent at any time by contacting Indeed Flex via Support.
            </UsSsnParagraph>
        </>
    );
};

export interface UsSsnFieldsProps extends UsSsnAppliedForProps {
    hideLegalText?: boolean;
}

export const UsSsnFields = ({
    formik,
    ssnFieldProps,
    ssnAppliedForFieldProps,
    hideLegalText,
    children,
}: PropsWithChildren<UsSsnFieldsProps>): JSX.Element => {
    const [showAppliedFor, setShowAppliedFor] = useState(true);
    const ssnAppliedForFieldName = ssnAppliedForFieldProps?.name || 'ssnAppliedFor';

    const ssnFieldName = ssnFieldProps?.name || 'ssn';
    const hasDuplicateError = getStatusIncludesDuplicatedSsn(formik.status, ssnFieldName);

    useEffect(() => {
        // This will disable the submit button until a field is touched
        if (hasDuplicateError) {
            formik.setFieldError(ssnFieldName, '');
        }
    }, [hasDuplicateError]);

    const ssnMeta = formik.getFieldMeta(ssnFieldName);
    const ssnValid = ssnMeta.touched && !ssnMeta.error;

    useEffect(() => {
        // Hide "Applied for" if ssn valid or if duplicated error
        setShowAppliedFor(!hasDuplicateError && !ssnValid);
    }, [ssnValid, setShowAppliedFor, hasDuplicateError]);

    const ssnAppliedFor = !!formik.values[ssnAppliedForFieldName]?.includes('true');

    return (
        <>
            <FormRow>
                <Col>
                    <FormikFields.MaskedTextField
                        label="U.S. Social Security Number"
                        aria-valuemax={9}
                        name="ssn"
                        mask="999999999"
                        hideOnBlur
                        removeCharRegex="-"
                        showInitialValue
                        showRequiredIndicator
                        {...ssnFieldProps}
                        inputProps={{
                            autoComplete: 'off',
                            required: !ssnAppliedFor,
                            className: 'dd-privacy-hidden',
                            disabled: ssnAppliedFor,
                            placeholder: ssnAppliedFor ? 'N/A' : '',
                            ...ssnFieldProps?.inputProps,
                        }}
                    />
                </Col>
            </FormRow>
            <FormRow>
                <Col>
                    {showAppliedFor && (
                        <UsSsnAppliedFor
                            formik={formik}
                            ssnAppliedForFieldProps={ssnAppliedForFieldProps}
                            ssnFieldProps={ssnFieldProps}
                        >
                            {children}
                        </UsSsnAppliedFor>
                    )}
                    {!hideLegalText && <SsnLegalText />}
                </Col>
            </FormRow>
        </>
    );
};
