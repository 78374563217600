/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
// Generated on 2024-11-07T14:06:34-06:00

export const WorkerProfileFragmentFragmentDoc = gql`
    fragment WorkerProfileFragment on WorkerNode {
        slug
        accountKey
        email
        firstName
        middleName
        lastName
        suffix
        title
        phone
        status
        mothersMaidenName
        dateOfBirth
        gender
        country
        flexWorkerId
        legalGender
        ssn
        workerAddress {
            address1
            address2
            city
            state
            postalCode
            country
            current
            start
            end
            lat
            lon
        }
    }
`;
export const RightToWorkTaskFragmentFragmentDoc = gql`
    fragment RightToWorkTaskFragment on TaskNode {
        slug
        accountKey
        email
        status
        workerDisplayStatus
        agentDisplayStatus
        progressStatus
        externalStatus
        type
        completedDate
        processedDate
        created
        lastModified
        completedBy
        completedCount
        redirectUrl
        metadata {
            ... on RightToWorkMetadataType {
                activeChecks {
                    checkType
                    status
                }
                rightToWork {
                    slug
                    shareCode
                    ukCitizen
                    euCitizen
                    externalId
                    expirationDate
                    externalWorkerId
                    registrationCode
                    ilr
                    pss
                    workingHourLimits {
                        slug
                        workingHourLimit
                        startTermDate
                        endTermDate
                    }
                }
                worker {
                    slug
                    email
                    dateOfBirth
                    firstName
                    middleName
                    lastName
                    flexWorkerId
                }
                workerGovernmentIds {
                    slug
                    idNumber
                    type
                    country
                    created
                    expiresOn
                    images {
                        slug
                        status
                        imageUrl
                        externalId
                        externalIdType
                        mimeType
                    }
                }
            }
        }
    }
`;
export const LocationFragmentFragmentDoc = gql`
    fragment LocationFragment on TaskLocationType {
        city
        state
        country
    }
`;
export const ClientFragmentFragmentDoc = gql`
    fragment ClientFragment on Client {
        country
        id
        name
        slug
        flexId
        onboardedId
    }
`;
export const RoleFragmentFragmentDoc = gql`
    fragment RoleFragment on Role {
        country
        id
        name
        slug
        flexId
        onboardedId
    }
`;
export const TaskWithWorkerAndPlacementFragmentFragmentDoc = gql`
    fragment TaskWithWorkerAndPlacementFragment on TaskNode {
        slug
        accountKey
        email
        status
        agentDisplayStatus
        progressStatus
        externalStatus
        type
        completedDate
        processedDate
        created
        lastModified
        completedBy
        completedCount
        metadata {
            ... on BackgroundCheckMetadataType {
                backgroundCheck {
                    package
                    title
                }
            }
            ... on ConditionalJobOfferMetadataType {
                workerDocuments {
                    slug
                }
            }
            ... on LocationRequirementsMetadataType {
                locationRequirements {
                    location {
                        ...LocationFragment
                    }
                }
            }
            ... on MediaRequirementsMetadataType {
                mediaRequirements {
                    location {
                        ...LocationFragment
                    }
                }
            }
            ... on OnboardedMetadataType {
                actor
                name
            }
        }
        worker {
            slug
            country
            firstName
            middleName
            lastName
            flexWorkerId
        }
        activePlacements {
            active
            id
            onboardedId
            slug
            completedAt
            status
            displayStatus
            client {
                ...ClientFragment
            }
            role {
                ...RoleFragment
            }
        }
    }
    ${LocationFragmentFragmentDoc}
    ${ClientFragmentFragmentDoc}
    ${RoleFragmentFragmentDoc}
`;
export const TaskFragmentFragmentDoc = gql`
    fragment TaskFragment on TaskNode {
        slug
        accountKey
        email
        status
        workerDisplayStatus
        agentDisplayStatus
        progressStatus
        externalStatus
        type
        completedDate
        processedDate
        created
        lastModified
        completedBy
        completedCount
        redirectUrl
        metadata {
            ... on BackgroundCheckMetadataType {
                backgroundCheck {
                    package
                    title
                    provider
                }
                templates {
                    slug
                    version
                    htmlContent
                    title
                    type
                    requirement
                    documentSlug
                }
                workerDocuments {
                    slug
                    created
                    action
                    templateType
                    signature
                    signatureDate
                }
            }
            ... on FormI9MetadataType {
                formI9 {
                    section2Complete
                    section2InviteSent
                    citizenshipStatus
                    alienDocType
                    eVerifyRequired
                    provider
                    resolutionComment
                }
                formI9WorkerGovernmentIds: workerGovernmentIds {
                    slug
                    type
                    country
                }
            }
            ... on RightToWorkMetadataType {
                activeChecks {
                    checkType
                    status
                }
                rightToWork {
                    slug
                    shareCode
                    ukCitizen
                    euCitizen
                    externalId
                    expirationDate
                    externalWorkerId
                    registrationCode
                    ilr
                    pss
                    fullLegalName
                    workingHourLimits {
                        slug
                        workingHourLimit
                        startTermDate
                        endTermDate
                    }
                }
                worker {
                    slug
                    email
                    dateOfBirth
                    firstName
                    middleName
                    lastName
                    flexWorkerId
                }
                workerGovernmentIds {
                    slug
                    idNumber
                    type
                    country
                    created
                    expiresOn
                    images {
                        slug
                        status
                        imageUrl
                        externalId
                        externalIdType
                        mimeType
                    }
                }
            }
            ... on LocationRequirementsMetadataType {
                locationRequirements {
                    location {
                        city
                        state
                        country
                    }
                }
                templates {
                    slug
                    version
                    htmlContent
                    title
                    type
                    requirement
                    documentSlug
                }
                workerDocuments {
                    slug
                    created
                    action
                    templateType
                    signature
                    signatureDate
                }
            }
            ... on ConditionalJobOfferMetadataType {
                templates {
                    slug
                    version
                    htmlContent
                    title
                    type
                    requirement
                    documentSlug
                }
                workerDocuments {
                    slug
                    created
                    action
                    templateType
                    signature
                    signatureDate
                }
            }
            ... on MediaRequirementsMetadataType {
                mediaRequirements {
                    location {
                        city
                        state
                        country
                    }
                }
                templates {
                    slug
                    version
                    htmlContent
                    title
                    type
                    requirement
                    documentSlug
                }
                workerDocuments {
                    slug
                    created
                    action
                    templateType
                    signature
                    signatureDate
                }
            }
            ... on UkDocumentsMetadataType {
                ukDocuments {
                    healthQuestionnaireCompletedDate
                    overseasQuestionnaireCompletedDate
                    medicalCondition
                    medicalAdjustments
                    livedOverseas
                }
                templates {
                    slug
                    version
                    htmlContent
                    title
                    type
                    requirement
                    documentSlug
                }
                workerDocuments {
                    slug
                    created
                    action
                    templateType
                    signature
                    signatureDate
                }
            }
            ... on UkPayrollMetadataType {
                ukPayroll {
                    slug
                    name
                    bankAccountNumber
                    sortCode
                    waitingForNiNumber
                    niNumber
                    taxInfo
                    studentLoan
                }
            }
            ... on EmergencyContactMetadataType {
                emergencyContact {
                    slug
                    name
                    phone
                    relationship
                }
            }
            ... on OnboardedMetadataType {
                name
                actor
                onboardedId
                content
                employeeFormUrl
                workerDocuments {
                    slug
                    created
                    action
                    templateType
                    signature
                    signatureDate
                }
            }
        }
    }
`;
export const PlacementFragmentFragmentDoc = gql`
    fragment PlacementFragment on Placement {
        active
        id
        onboardedId
        slug
        completedAt
        status
        displayStatus
        client {
            ...ClientFragment
        }
        role {
            ...RoleFragment
        }
        activeTasks {
            ...TaskFragment
        }
    }
    ${ClientFragmentFragmentDoc}
    ${RoleFragmentFragmentDoc}
    ${TaskFragmentFragmentDoc}
`;
export const PlacementWithWorkerFragmentFragmentDoc = gql`
    fragment PlacementWithWorkerFragment on Placement {
        id
        onboardedId
        slug
        completedAt
        created
        lastModified
        status
        interviewedBy
        displayStatus
        client {
            ...ClientFragment
        }
        role {
            ...RoleFragment
        }
        worker {
            slug
            country
            firstName
            middleName
            lastName
            flexWorkerId
            flexOnboardingStatus
        }
    }
    ${ClientFragmentFragmentDoc}
    ${RoleFragmentFragmentDoc}
`;
export const CompleteBackgroundCheckDocument = gql`
    mutation CompleteBackgroundCheck($input: CompleteBackgroundCheckInput!) {
        completeBackgroundCheck(input: $input) {
            result {
                slug
                accountKey
                status
                type
                completedDate
                processedDate
                metadata {
                    backgroundCheck {
                        package
                        provider
                        resolutionDate
                    }
                }
            }
        }
    }
`;
export type CompleteBackgroundCheckMutationFn = Apollo.MutationFunction<
    CompleteBackgroundCheckMutation,
    CompleteBackgroundCheckMutationVariables
>;

/**
 * __useCompleteBackgroundCheckMutation__
 *
 * To run a mutation, you first call `useCompleteBackgroundCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteBackgroundCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeBackgroundCheckMutation, { data, loading, error }] = useCompleteBackgroundCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteBackgroundCheckMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CompleteBackgroundCheckMutation,
        CompleteBackgroundCheckMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CompleteBackgroundCheckMutation,
        CompleteBackgroundCheckMutationVariables
    >(CompleteBackgroundCheckDocument, options);
}
export type CompleteBackgroundCheckMutationHookResult = ReturnType<
    typeof useCompleteBackgroundCheckMutation
>;
export type CompleteBackgroundCheckMutationResult =
    Apollo.MutationResult<CompleteBackgroundCheckMutation>;
export type CompleteBackgroundCheckMutationOptions = Apollo.BaseMutationOptions<
    CompleteBackgroundCheckMutation,
    CompleteBackgroundCheckMutationVariables
>;
export const CompleteBankDetailsSsnDocument = gql`
    mutation CompleteBankDetailsSsn($input: CompleteBankDetailsSsnInput!) {
        completeBankDetailsSsn(input: $input) {
            result {
                slug
                status
                externalStatus
                type
            }
        }
    }
`;
export type CompleteBankDetailsSsnMutationFn = Apollo.MutationFunction<
    CompleteBankDetailsSsnMutation,
    CompleteBankDetailsSsnMutationVariables
>;

/**
 * __useCompleteBankDetailsSsnMutation__
 *
 * To run a mutation, you first call `useCompleteBankDetailsSsnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteBankDetailsSsnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeBankDetailsSsnMutation, { data, loading, error }] = useCompleteBankDetailsSsnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteBankDetailsSsnMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CompleteBankDetailsSsnMutation,
        CompleteBankDetailsSsnMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CompleteBankDetailsSsnMutation,
        CompleteBankDetailsSsnMutationVariables
    >(CompleteBankDetailsSsnDocument, options);
}
export type CompleteBankDetailsSsnMutationHookResult = ReturnType<
    typeof useCompleteBankDetailsSsnMutation
>;
export type CompleteBankDetailsSsnMutationResult =
    Apollo.MutationResult<CompleteBankDetailsSsnMutation>;
export type CompleteBankDetailsSsnMutationOptions = Apollo.BaseMutationOptions<
    CompleteBankDetailsSsnMutation,
    CompleteBankDetailsSsnMutationVariables
>;
export const UpdateTaskStatusDocument = gql`
    mutation UpdateTaskStatus($slug: ID!, $status: TaskStatus!) {
        updateTask(input: { slug: $slug, status: $status }) {
            result {
                slug
                status
                completedDate
            }
        }
    }
`;
export type UpdateTaskStatusMutationFn = Apollo.MutationFunction<
    UpdateTaskStatusMutation,
    UpdateTaskStatusMutationVariables
>;

/**
 * __useUpdateTaskStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskStatusMutation, { data, loading, error }] = useUpdateTaskStatusMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateTaskStatusMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateTaskStatusMutation,
        UpdateTaskStatusMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateTaskStatusMutation, UpdateTaskStatusMutationVariables>(
        UpdateTaskStatusDocument,
        options
    );
}
export type UpdateTaskStatusMutationHookResult = ReturnType<typeof useUpdateTaskStatusMutation>;
export type UpdateTaskStatusMutationResult = Apollo.MutationResult<UpdateTaskStatusMutation>;
export type UpdateTaskStatusMutationOptions = Apollo.BaseMutationOptions<
    UpdateTaskStatusMutation,
    UpdateTaskStatusMutationVariables
>;
export const UpdateWorkerGovernmentIdDocument = gql`
    mutation UpdateWorkerGovernmentId($slug: ID!, $governmentId: WorkerGovernmentIdInput!) {
        updateWorker(input: { slug: $slug, governmentIds: [$governmentId] }) {
            result {
                slug
                governmentIds {
                    slug
                    idNumber
                    type
                    country
                    images {
                        slug
                        imageUrl
                        status
                    }
                }
            }
        }
    }
`;
export type UpdateWorkerGovernmentIdMutationFn = Apollo.MutationFunction<
    UpdateWorkerGovernmentIdMutation,
    UpdateWorkerGovernmentIdMutationVariables
>;

/**
 * __useUpdateWorkerGovernmentIdMutation__
 *
 * To run a mutation, you first call `useUpdateWorkerGovernmentIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkerGovernmentIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkerGovernmentIdMutation, { data, loading, error }] = useUpdateWorkerGovernmentIdMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      governmentId: // value for 'governmentId'
 *   },
 * });
 */
export function useUpdateWorkerGovernmentIdMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateWorkerGovernmentIdMutation,
        UpdateWorkerGovernmentIdMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateWorkerGovernmentIdMutation,
        UpdateWorkerGovernmentIdMutationVariables
    >(UpdateWorkerGovernmentIdDocument, options);
}
export type UpdateWorkerGovernmentIdMutationHookResult = ReturnType<
    typeof useUpdateWorkerGovernmentIdMutation
>;
export type UpdateWorkerGovernmentIdMutationResult =
    Apollo.MutationResult<UpdateWorkerGovernmentIdMutation>;
export type UpdateWorkerGovernmentIdMutationOptions = Apollo.BaseMutationOptions<
    UpdateWorkerGovernmentIdMutation,
    UpdateWorkerGovernmentIdMutationVariables
>;
export const DeleteWorkerGovernmentIdsDocument = gql`
    mutation DeleteWorkerGovernmentIds(
        $deleteWorkerGovernmentIdsInput: DeleteWorkerGovernmentIdsInput!
    ) {
        deleteWorkerGovernmentIds(input: $deleteWorkerGovernmentIdsInput) {
            result {
                workerGovernmentIds {
                    slug
                    success
                }
            }
            errors
        }
    }
`;
export type DeleteWorkerGovernmentIdsMutationFn = Apollo.MutationFunction<
    DeleteWorkerGovernmentIdsMutation,
    DeleteWorkerGovernmentIdsMutationVariables
>;

/**
 * __useDeleteWorkerGovernmentIdsMutation__
 *
 * To run a mutation, you first call `useDeleteWorkerGovernmentIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkerGovernmentIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkerGovernmentIdsMutation, { data, loading, error }] = useDeleteWorkerGovernmentIdsMutation({
 *   variables: {
 *      deleteWorkerGovernmentIdsInput: // value for 'deleteWorkerGovernmentIdsInput'
 *   },
 * });
 */
export function useDeleteWorkerGovernmentIdsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteWorkerGovernmentIdsMutation,
        DeleteWorkerGovernmentIdsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteWorkerGovernmentIdsMutation,
        DeleteWorkerGovernmentIdsMutationVariables
    >(DeleteWorkerGovernmentIdsDocument, options);
}
export type DeleteWorkerGovernmentIdsMutationHookResult = ReturnType<
    typeof useDeleteWorkerGovernmentIdsMutation
>;
export type DeleteWorkerGovernmentIdsMutationResult =
    Apollo.MutationResult<DeleteWorkerGovernmentIdsMutation>;
export type DeleteWorkerGovernmentIdsMutationOptions = Apollo.BaseMutationOptions<
    DeleteWorkerGovernmentIdsMutation,
    DeleteWorkerGovernmentIdsMutationVariables
>;
export const UpdateWorkerDocument = gql`
    mutation UpdateWorker($updateWorkerInput: UpdateWorkerInput!) {
        updateWorker(input: $updateWorkerInput) {
            result {
                slug
                firstName
                middleName
                lastName
                phone
                dateOfBirth
                gender
                address {
                    address1
                    address2
                    city
                    state
                    postalCode
                    current
                    country
                }
            }
        }
    }
`;
export type UpdateWorkerMutationFn = Apollo.MutationFunction<
    UpdateWorkerMutation,
    UpdateWorkerMutationVariables
>;

/**
 * __useUpdateWorkerMutation__
 *
 * To run a mutation, you first call `useUpdateWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkerMutation, { data, loading, error }] = useUpdateWorkerMutation({
 *   variables: {
 *      updateWorkerInput: // value for 'updateWorkerInput'
 *   },
 * });
 */
export function useUpdateWorkerMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateWorkerMutation, UpdateWorkerMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateWorkerMutation, UpdateWorkerMutationVariables>(
        UpdateWorkerDocument,
        options
    );
}
export type UpdateWorkerMutationHookResult = ReturnType<typeof useUpdateWorkerMutation>;
export type UpdateWorkerMutationResult = Apollo.MutationResult<UpdateWorkerMutation>;
export type UpdateWorkerMutationOptions = Apollo.BaseMutationOptions<
    UpdateWorkerMutation,
    UpdateWorkerMutationVariables
>;
export const CreateTasksDocument = gql`
    mutation CreateTasks($tasks: [CreateTaskInput!]!) {
        createTasks(input: { tasks: $tasks }) {
            result {
                tasks {
                    type
                    slug
                    status
                }
            }
            errors
        }
    }
`;
export type CreateTasksMutationFn = Apollo.MutationFunction<
    CreateTasksMutation,
    CreateTasksMutationVariables
>;

/**
 * __useCreateTasksMutation__
 *
 * To run a mutation, you first call `useCreateTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTasksMutation, { data, loading, error }] = useCreateTasksMutation({
 *   variables: {
 *      tasks: // value for 'tasks'
 *   },
 * });
 */
export function useCreateTasksMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateTasksMutation, CreateTasksMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateTasksMutation, CreateTasksMutationVariables>(
        CreateTasksDocument,
        options
    );
}
export type CreateTasksMutationHookResult = ReturnType<typeof useCreateTasksMutation>;
export type CreateTasksMutationResult = Apollo.MutationResult<CreateTasksMutation>;
export type CreateTasksMutationOptions = Apollo.BaseMutationOptions<
    CreateTasksMutation,
    CreateTasksMutationVariables
>;
export const CreateWorkerDocument = gql`
    mutation CreateWorker($input: CreateWorkerInput!) {
        createWorker(input: $input) {
            result {
                email
                accountKey
                slug
            }
        }
    }
`;
export type CreateWorkerMutationFn = Apollo.MutationFunction<
    CreateWorkerMutation,
    CreateWorkerMutationVariables
>;

/**
 * __useCreateWorkerMutation__
 *
 * To run a mutation, you first call `useCreateWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkerMutation, { data, loading, error }] = useCreateWorkerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkerMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateWorkerMutation, CreateWorkerMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateWorkerMutation, CreateWorkerMutationVariables>(
        CreateWorkerDocument,
        options
    );
}
export type CreateWorkerMutationHookResult = ReturnType<typeof useCreateWorkerMutation>;
export type CreateWorkerMutationResult = Apollo.MutationResult<CreateWorkerMutation>;
export type CreateWorkerMutationOptions = Apollo.BaseMutationOptions<
    CreateWorkerMutation,
    CreateWorkerMutationVariables
>;
export const CreateWorkerDocumentDocument = gql`
    mutation CreateWorkerDocument($input: CreateWorkerDocumentInput!) {
        createWorkerDocument(input: $input) {
            result {
                created
                action
                slug
                templateType
                signature
            }
        }
    }
`;
export type CreateWorkerDocumentMutationFn = Apollo.MutationFunction<
    CreateWorkerDocumentMutation,
    CreateWorkerDocumentMutationVariables
>;

/**
 * __useCreateWorkerDocumentMutation__
 *
 * To run a mutation, you first call `useCreateWorkerDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkerDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkerDocumentMutation, { data, loading, error }] = useCreateWorkerDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkerDocumentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateWorkerDocumentMutation,
        CreateWorkerDocumentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateWorkerDocumentMutation, CreateWorkerDocumentMutationVariables>(
        CreateWorkerDocumentDocument,
        options
    );
}
export type CreateWorkerDocumentMutationHookResult = ReturnType<
    typeof useCreateWorkerDocumentMutation
>;
export type CreateWorkerDocumentMutationResult =
    Apollo.MutationResult<CreateWorkerDocumentMutation>;
export type CreateWorkerDocumentMutationOptions = Apollo.BaseMutationOptions<
    CreateWorkerDocumentMutation,
    CreateWorkerDocumentMutationVariables
>;
export const UpdateTaskDocument = gql`
    mutation UpdateTask($input: UpdateTaskInput!) {
        updateTask(input: $input) {
            result {
                status
            }
        }
    }
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
        UpdateTaskDocument,
        options
    );
}
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
>;
export const CreateRightToWorkDocument = gql`
    mutation CreateRightToWork($input: CreateRightToWorkInput!) {
        createRightToWork(input: $input) {
            status
            externalId
            entityId
        }
    }
`;
export type CreateRightToWorkMutationFn = Apollo.MutationFunction<
    CreateRightToWorkMutation,
    CreateRightToWorkMutationVariables
>;

/**
 * __useCreateRightToWorkMutation__
 *
 * To run a mutation, you first call `useCreateRightToWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRightToWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRightToWorkMutation, { data, loading, error }] = useCreateRightToWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRightToWorkMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateRightToWorkMutation,
        CreateRightToWorkMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateRightToWorkMutation, CreateRightToWorkMutationVariables>(
        CreateRightToWorkDocument,
        options
    );
}
export type CreateRightToWorkMutationHookResult = ReturnType<typeof useCreateRightToWorkMutation>;
export type CreateRightToWorkMutationResult = Apollo.MutationResult<CreateRightToWorkMutation>;
export type CreateRightToWorkMutationOptions = Apollo.BaseMutationOptions<
    CreateRightToWorkMutation,
    CreateRightToWorkMutationVariables
>;
export const UpdateRightToWorkGovernmentDocumentsDocument = gql`
    mutation UpdateRightToWorkGovernmentDocuments(
        $input: UpdateRightToWorkGovernmentDocumentsInput!
    ) {
        updateRightToWorkGovernmentDocuments(input: $input) {
            status
        }
    }
`;
export type UpdateRightToWorkGovernmentDocumentsMutationFn = Apollo.MutationFunction<
    UpdateRightToWorkGovernmentDocumentsMutation,
    UpdateRightToWorkGovernmentDocumentsMutationVariables
>;

/**
 * __useUpdateRightToWorkGovernmentDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateRightToWorkGovernmentDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRightToWorkGovernmentDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRightToWorkGovernmentDocumentsMutation, { data, loading, error }] = useUpdateRightToWorkGovernmentDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRightToWorkGovernmentDocumentsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateRightToWorkGovernmentDocumentsMutation,
        UpdateRightToWorkGovernmentDocumentsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateRightToWorkGovernmentDocumentsMutation,
        UpdateRightToWorkGovernmentDocumentsMutationVariables
    >(UpdateRightToWorkGovernmentDocumentsDocument, options);
}
export type UpdateRightToWorkGovernmentDocumentsMutationHookResult = ReturnType<
    typeof useUpdateRightToWorkGovernmentDocumentsMutation
>;
export type UpdateRightToWorkGovernmentDocumentsMutationResult =
    Apollo.MutationResult<UpdateRightToWorkGovernmentDocumentsMutation>;
export type UpdateRightToWorkGovernmentDocumentsMutationOptions = Apollo.BaseMutationOptions<
    UpdateRightToWorkGovernmentDocumentsMutation,
    UpdateRightToWorkGovernmentDocumentsMutationVariables
>;
export const DeleteWorkerDocumentsByTaskDocument = gql`
    mutation DeleteWorkerDocumentsByTask($input: DeleteWorkerDocumentsByTaskInput!) {
        deleteWorkerDocumentsByTask(input: $input) {
            workerDocuments
        }
    }
`;
export type DeleteWorkerDocumentsByTaskMutationFn = Apollo.MutationFunction<
    DeleteWorkerDocumentsByTaskMutation,
    DeleteWorkerDocumentsByTaskMutationVariables
>;

/**
 * __useDeleteWorkerDocumentsByTaskMutation__
 *
 * To run a mutation, you first call `useDeleteWorkerDocumentsByTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkerDocumentsByTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkerDocumentsByTaskMutation, { data, loading, error }] = useDeleteWorkerDocumentsByTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWorkerDocumentsByTaskMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteWorkerDocumentsByTaskMutation,
        DeleteWorkerDocumentsByTaskMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteWorkerDocumentsByTaskMutation,
        DeleteWorkerDocumentsByTaskMutationVariables
    >(DeleteWorkerDocumentsByTaskDocument, options);
}
export type DeleteWorkerDocumentsByTaskMutationHookResult = ReturnType<
    typeof useDeleteWorkerDocumentsByTaskMutation
>;
export type DeleteWorkerDocumentsByTaskMutationResult =
    Apollo.MutationResult<DeleteWorkerDocumentsByTaskMutation>;
export type DeleteWorkerDocumentsByTaskMutationOptions = Apollo.BaseMutationOptions<
    DeleteWorkerDocumentsByTaskMutation,
    DeleteWorkerDocumentsByTaskMutationVariables
>;
export const VerifyRightToWorkDocument = gql`
    mutation VerifyRightToWork($input: VerifyRightToWorkInput!) {
        verifyRightToWork(input: $input) {
            status
            taskSlug
            credasStatus
        }
    }
`;
export type VerifyRightToWorkMutationFn = Apollo.MutationFunction<
    VerifyRightToWorkMutation,
    VerifyRightToWorkMutationVariables
>;

/**
 * __useVerifyRightToWorkMutation__
 *
 * To run a mutation, you first call `useVerifyRightToWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyRightToWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyRightToWorkMutation, { data, loading, error }] = useVerifyRightToWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyRightToWorkMutation(
    baseOptions?: Apollo.MutationHookOptions<
        VerifyRightToWorkMutation,
        VerifyRightToWorkMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<VerifyRightToWorkMutation, VerifyRightToWorkMutationVariables>(
        VerifyRightToWorkDocument,
        options
    );
}
export type VerifyRightToWorkMutationHookResult = ReturnType<typeof useVerifyRightToWorkMutation>;
export type VerifyRightToWorkMutationResult = Apollo.MutationResult<VerifyRightToWorkMutation>;
export type VerifyRightToWorkMutationOptions = Apollo.BaseMutationOptions<
    VerifyRightToWorkMutation,
    VerifyRightToWorkMutationVariables
>;
export const CompleteEmergencyContactDocument = gql`
    mutation CompleteEmergencyContact($input: CompleteEmergencyContactInput!) {
        completeEmergencyContact(input: $input) {
            result {
                status
                redirectUrl
                completedCount
                externalStatus
                slug
                accountKey
                email
                type
                completedDate
                processedDate
                metadata {
                    emergencyContact {
                        name
                        phone
                        relationship
                    }
                }
            }
        }
    }
`;
export type CompleteEmergencyContactMutationFn = Apollo.MutationFunction<
    CompleteEmergencyContactMutation,
    CompleteEmergencyContactMutationVariables
>;

/**
 * __useCompleteEmergencyContactMutation__
 *
 * To run a mutation, you first call `useCompleteEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeEmergencyContactMutation, { data, loading, error }] = useCompleteEmergencyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteEmergencyContactMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CompleteEmergencyContactMutation,
        CompleteEmergencyContactMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CompleteEmergencyContactMutation,
        CompleteEmergencyContactMutationVariables
    >(CompleteEmergencyContactDocument, options);
}
export type CompleteEmergencyContactMutationHookResult = ReturnType<
    typeof useCompleteEmergencyContactMutation
>;
export type CompleteEmergencyContactMutationResult =
    Apollo.MutationResult<CompleteEmergencyContactMutation>;
export type CompleteEmergencyContactMutationOptions = Apollo.BaseMutationOptions<
    CompleteEmergencyContactMutation,
    CompleteEmergencyContactMutationVariables
>;
export const CompleteUkPayrollDocument = gql`
    mutation CompleteUkPayroll($input: UkPayrollInput!) {
        completeUkPayroll(input: $input) {
            result {
                status
                redirectUrl
                completedCount
                externalStatus
                slug
                accountKey
                email
                type
                completedDate
                processedDate
            }
        }
    }
`;
export type CompleteUkPayrollMutationFn = Apollo.MutationFunction<
    CompleteUkPayrollMutation,
    CompleteUkPayrollMutationVariables
>;

/**
 * __useCompleteUkPayrollMutation__
 *
 * To run a mutation, you first call `useCompleteUkPayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteUkPayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeUkPayrollMutation, { data, loading, error }] = useCompleteUkPayrollMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteUkPayrollMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CompleteUkPayrollMutation,
        CompleteUkPayrollMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CompleteUkPayrollMutation, CompleteUkPayrollMutationVariables>(
        CompleteUkPayrollDocument,
        options
    );
}
export type CompleteUkPayrollMutationHookResult = ReturnType<typeof useCompleteUkPayrollMutation>;
export type CompleteUkPayrollMutationResult = Apollo.MutationResult<CompleteUkPayrollMutation>;
export type CompleteUkPayrollMutationOptions = Apollo.BaseMutationOptions<
    CompleteUkPayrollMutation,
    CompleteUkPayrollMutationVariables
>;
export const CompleteUkDocumentsDocument = gql`
    mutation CompleteUkDocuments($input: CompleteUkDocumentsInput!) {
        completeUkDocuments(input: $input) {
            result {
                status
                redirectUrl
                completedCount
                externalStatus
                slug
                accountKey
                email
                type
                completedDate
                processedDate
                metadata {
                    ukDocuments {
                        healthQuestionnaireCompletedDate
                        overseasQuestionnaireCompletedDate
                        medicalCondition
                        medicalAdjustments
                        livedOverseas
                    }
                }
            }
        }
    }
`;
export type CompleteUkDocumentsMutationFn = Apollo.MutationFunction<
    CompleteUkDocumentsMutation,
    CompleteUkDocumentsMutationVariables
>;

/**
 * __useCompleteUkDocumentsMutation__
 *
 * To run a mutation, you first call `useCompleteUkDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteUkDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeUkDocumentsMutation, { data, loading, error }] = useCompleteUkDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteUkDocumentsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CompleteUkDocumentsMutation,
        CompleteUkDocumentsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CompleteUkDocumentsMutation, CompleteUkDocumentsMutationVariables>(
        CompleteUkDocumentsDocument,
        options
    );
}
export type CompleteUkDocumentsMutationHookResult = ReturnType<
    typeof useCompleteUkDocumentsMutation
>;
export type CompleteUkDocumentsMutationResult = Apollo.MutationResult<CompleteUkDocumentsMutation>;
export type CompleteUkDocumentsMutationOptions = Apollo.BaseMutationOptions<
    CompleteUkDocumentsMutation,
    CompleteUkDocumentsMutationVariables
>;
export const UpdateRightToWorkCitizenshipDocument = gql`
    mutation UpdateRightToWorkCitizenship($input: UpdateRightToWorkCitizenshipInput!) {
        updateRightToWorkCitizenship(input: $input) {
            status
            ukCitizen
        }
    }
`;
export type UpdateRightToWorkCitizenshipMutationFn = Apollo.MutationFunction<
    UpdateRightToWorkCitizenshipMutation,
    UpdateRightToWorkCitizenshipMutationVariables
>;

/**
 * __useUpdateRightToWorkCitizenshipMutation__
 *
 * To run a mutation, you first call `useUpdateRightToWorkCitizenshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRightToWorkCitizenshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRightToWorkCitizenshipMutation, { data, loading, error }] = useUpdateRightToWorkCitizenshipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRightToWorkCitizenshipMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateRightToWorkCitizenshipMutation,
        UpdateRightToWorkCitizenshipMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateRightToWorkCitizenshipMutation,
        UpdateRightToWorkCitizenshipMutationVariables
    >(UpdateRightToWorkCitizenshipDocument, options);
}
export type UpdateRightToWorkCitizenshipMutationHookResult = ReturnType<
    typeof useUpdateRightToWorkCitizenshipMutation
>;
export type UpdateRightToWorkCitizenshipMutationResult =
    Apollo.MutationResult<UpdateRightToWorkCitizenshipMutation>;
export type UpdateRightToWorkCitizenshipMutationOptions = Apollo.BaseMutationOptions<
    UpdateRightToWorkCitizenshipMutation,
    UpdateRightToWorkCitizenshipMutationVariables
>;
export const CompleteRightToWorkDocument = gql`
    mutation CompleteRightToWork($input: CompleteRightToWorkInput!) {
        completeRightToWork(input: $input) {
            result {
                status
                slug
                completedDate
                type
                metadata {
                    ... on RightToWorkMetadataResult {
                        rightToWork {
                            shareCode
                            ukCitizen
                        }
                    }
                }
            }
        }
    }
`;
export type CompleteRightToWorkMutationFn = Apollo.MutationFunction<
    CompleteRightToWorkMutation,
    CompleteRightToWorkMutationVariables
>;

/**
 * __useCompleteRightToWorkMutation__
 *
 * To run a mutation, you first call `useCompleteRightToWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteRightToWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeRightToWorkMutation, { data, loading, error }] = useCompleteRightToWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteRightToWorkMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CompleteRightToWorkMutation,
        CompleteRightToWorkMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CompleteRightToWorkMutation, CompleteRightToWorkMutationVariables>(
        CompleteRightToWorkDocument,
        options
    );
}
export type CompleteRightToWorkMutationHookResult = ReturnType<
    typeof useCompleteRightToWorkMutation
>;
export type CompleteRightToWorkMutationResult = Apollo.MutationResult<CompleteRightToWorkMutation>;
export type CompleteRightToWorkMutationOptions = Apollo.BaseMutationOptions<
    CompleteRightToWorkMutation,
    CompleteRightToWorkMutationVariables
>;
export const UpdateActiveRightToWorkDocument = gql`
    mutation UpdateActiveRightToWork($input: UpdateActiveRightToWorkInput!) {
        updateActiveRightToWork(input: $input) {
            status
            updated
        }
    }
`;
export type UpdateActiveRightToWorkMutationFn = Apollo.MutationFunction<
    UpdateActiveRightToWorkMutation,
    UpdateActiveRightToWorkMutationVariables
>;

/**
 * __useUpdateActiveRightToWorkMutation__
 *
 * To run a mutation, you first call `useUpdateActiveRightToWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveRightToWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveRightToWorkMutation, { data, loading, error }] = useUpdateActiveRightToWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActiveRightToWorkMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateActiveRightToWorkMutation,
        UpdateActiveRightToWorkMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateActiveRightToWorkMutation,
        UpdateActiveRightToWorkMutationVariables
    >(UpdateActiveRightToWorkDocument, options);
}
export type UpdateActiveRightToWorkMutationHookResult = ReturnType<
    typeof useUpdateActiveRightToWorkMutation
>;
export type UpdateActiveRightToWorkMutationResult =
    Apollo.MutationResult<UpdateActiveRightToWorkMutation>;
export type UpdateActiveRightToWorkMutationOptions = Apollo.BaseMutationOptions<
    UpdateActiveRightToWorkMutation,
    UpdateActiveRightToWorkMutationVariables
>;
export const GenerateWorkbrightI9RedirectUrlDocument = gql`
    mutation GenerateWorkbrightI9RedirectUrl($input: GenerateWorkbrightI9RedirectUrlInput!) {
        generateWorkbrightI9RedirectUrl(input: $input) {
            redirectUrl
        }
    }
`;
export type GenerateWorkbrightI9RedirectUrlMutationFn = Apollo.MutationFunction<
    GenerateWorkbrightI9RedirectUrlMutation,
    GenerateWorkbrightI9RedirectUrlMutationVariables
>;

/**
 * __useGenerateWorkbrightI9RedirectUrlMutation__
 *
 * To run a mutation, you first call `useGenerateWorkbrightI9RedirectUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateWorkbrightI9RedirectUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateWorkbrightI9RedirectUrlMutation, { data, loading, error }] = useGenerateWorkbrightI9RedirectUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateWorkbrightI9RedirectUrlMutation(
    baseOptions?: Apollo.MutationHookOptions<
        GenerateWorkbrightI9RedirectUrlMutation,
        GenerateWorkbrightI9RedirectUrlMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        GenerateWorkbrightI9RedirectUrlMutation,
        GenerateWorkbrightI9RedirectUrlMutationVariables
    >(GenerateWorkbrightI9RedirectUrlDocument, options);
}
export type GenerateWorkbrightI9RedirectUrlMutationHookResult = ReturnType<
    typeof useGenerateWorkbrightI9RedirectUrlMutation
>;
export type GenerateWorkbrightI9RedirectUrlMutationResult =
    Apollo.MutationResult<GenerateWorkbrightI9RedirectUrlMutation>;
export type GenerateWorkbrightI9RedirectUrlMutationOptions = Apollo.BaseMutationOptions<
    GenerateWorkbrightI9RedirectUrlMutation,
    GenerateWorkbrightI9RedirectUrlMutationVariables
>;
export const ResetTaskDocument = gql`
    mutation ResetTask($input: ResetTaskInput!) {
        resetTask(input: $input) {
            result {
                type
                slug
                status
            }
        }
    }
`;
export type ResetTaskMutationFn = Apollo.MutationFunction<
    ResetTaskMutation,
    ResetTaskMutationVariables
>;

/**
 * __useResetTaskMutation__
 *
 * To run a mutation, you first call `useResetTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetTaskMutation, { data, loading, error }] = useResetTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetTaskMutation(
    baseOptions?: Apollo.MutationHookOptions<ResetTaskMutation, ResetTaskMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ResetTaskMutation, ResetTaskMutationVariables>(
        ResetTaskDocument,
        options
    );
}
export type ResetTaskMutationHookResult = ReturnType<typeof useResetTaskMutation>;
export type ResetTaskMutationResult = Apollo.MutationResult<ResetTaskMutation>;
export type ResetTaskMutationOptions = Apollo.BaseMutationOptions<
    ResetTaskMutation,
    ResetTaskMutationVariables
>;
export const ImportMissingFlexTaskDataDocument = gql`
    mutation ImportMissingFlexTaskData($input: ImportMissingFlexTaskDataInput!) {
        importMissingFlexTaskData(input: $input) {
            message
        }
    }
`;
export type ImportMissingFlexTaskDataMutationFn = Apollo.MutationFunction<
    ImportMissingFlexTaskDataMutation,
    ImportMissingFlexTaskDataMutationVariables
>;

/**
 * __useImportMissingFlexTaskDataMutation__
 *
 * To run a mutation, you first call `useImportMissingFlexTaskDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportMissingFlexTaskDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importMissingFlexTaskDataMutation, { data, loading, error }] = useImportMissingFlexTaskDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportMissingFlexTaskDataMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ImportMissingFlexTaskDataMutation,
        ImportMissingFlexTaskDataMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        ImportMissingFlexTaskDataMutation,
        ImportMissingFlexTaskDataMutationVariables
    >(ImportMissingFlexTaskDataDocument, options);
}
export type ImportMissingFlexTaskDataMutationHookResult = ReturnType<
    typeof useImportMissingFlexTaskDataMutation
>;
export type ImportMissingFlexTaskDataMutationResult =
    Apollo.MutationResult<ImportMissingFlexTaskDataMutation>;
export type ImportMissingFlexTaskDataMutationOptions = Apollo.BaseMutationOptions<
    ImportMissingFlexTaskDataMutation,
    ImportMissingFlexTaskDataMutationVariables
>;
export const CreateBackgroundCheckPackageDocument = gql`
    mutation createBackgroundCheckPackage($input: CreateBackgroundCheckPackageInput!) {
        createBackgroundCheckPackage(input: $input) {
            result {
                package
                name
                title
                provider
            }
        }
    }
`;
export type CreateBackgroundCheckPackageMutationFn = Apollo.MutationFunction<
    CreateBackgroundCheckPackageMutation,
    CreateBackgroundCheckPackageMutationVariables
>;

/**
 * __useCreateBackgroundCheckPackageMutation__
 *
 * To run a mutation, you first call `useCreateBackgroundCheckPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBackgroundCheckPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBackgroundCheckPackageMutation, { data, loading, error }] = useCreateBackgroundCheckPackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBackgroundCheckPackageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateBackgroundCheckPackageMutation,
        CreateBackgroundCheckPackageMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateBackgroundCheckPackageMutation,
        CreateBackgroundCheckPackageMutationVariables
    >(CreateBackgroundCheckPackageDocument, options);
}
export type CreateBackgroundCheckPackageMutationHookResult = ReturnType<
    typeof useCreateBackgroundCheckPackageMutation
>;
export type CreateBackgroundCheckPackageMutationResult =
    Apollo.MutationResult<CreateBackgroundCheckPackageMutation>;
export type CreateBackgroundCheckPackageMutationOptions = Apollo.BaseMutationOptions<
    CreateBackgroundCheckPackageMutation,
    CreateBackgroundCheckPackageMutationVariables
>;
export const CreatePlacementDocument = gql`
    mutation CreatePlacement($input: CreatePlacementInput!) {
        createPlacement(input: $input) {
            placement {
                ...PlacementFragment
            }
        }
    }
    ${PlacementFragmentFragmentDoc}
`;
export type CreatePlacementMutationFn = Apollo.MutationFunction<
    CreatePlacementMutation,
    CreatePlacementMutationVariables
>;

/**
 * __useCreatePlacementMutation__
 *
 * To run a mutation, you first call `useCreatePlacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlacementMutation, { data, loading, error }] = useCreatePlacementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlacementMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePlacementMutation,
        CreatePlacementMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreatePlacementMutation, CreatePlacementMutationVariables>(
        CreatePlacementDocument,
        options
    );
}
export type CreatePlacementMutationHookResult = ReturnType<typeof useCreatePlacementMutation>;
export type CreatePlacementMutationResult = Apollo.MutationResult<CreatePlacementMutation>;
export type CreatePlacementMutationOptions = Apollo.BaseMutationOptions<
    CreatePlacementMutation,
    CreatePlacementMutationVariables
>;
export const UpdatePlacementDocument = gql`
    mutation UpdatePlacement($input: UpdatePlacementInput!) {
        updatePlacement(input: $input) {
            placement {
                ...PlacementFragment
            }
        }
    }
    ${PlacementFragmentFragmentDoc}
`;
export type UpdatePlacementMutationFn = Apollo.MutationFunction<
    UpdatePlacementMutation,
    UpdatePlacementMutationVariables
>;

/**
 * __useUpdatePlacementMutation__
 *
 * To run a mutation, you first call `useUpdatePlacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlacementMutation, { data, loading, error }] = useUpdatePlacementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlacementMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePlacementMutation,
        UpdatePlacementMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdatePlacementMutation, UpdatePlacementMutationVariables>(
        UpdatePlacementDocument,
        options
    );
}
export type UpdatePlacementMutationHookResult = ReturnType<typeof useUpdatePlacementMutation>;
export type UpdatePlacementMutationResult = Apollo.MutationResult<UpdatePlacementMutation>;
export type UpdatePlacementMutationOptions = Apollo.BaseMutationOptions<
    UpdatePlacementMutation,
    UpdatePlacementMutationVariables
>;
export const GetAgentDocument = gql`
    query GetAgent(
        $userEmail: String
        $country: WorkercomplianceserviceappAgentCountryChoices
        $slug: String
    ) {
        getAgents(user_Email: $userEmail, country: $country, slug: $slug) {
            edges {
                node {
                    slug
                    country
                    complianceAgent
                }
            }
        }
    }
`;

/**
 * __useGetAgentQuery__
 *
 * To run a query within a React component, call `useGetAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentQuery({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *      country: // value for 'country'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetAgentQuery(
    baseOptions?: Apollo.QueryHookOptions<GetAgentQuery, GetAgentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetAgentQuery, GetAgentQueryVariables>(GetAgentDocument, options);
}
export function useGetAgentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAgentQuery, GetAgentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetAgentQuery, GetAgentQueryVariables>(GetAgentDocument, options);
}
export type GetAgentQueryHookResult = ReturnType<typeof useGetAgentQuery>;
export type GetAgentLazyQueryHookResult = ReturnType<typeof useGetAgentLazyQuery>;
export type GetAgentQueryResult = Apollo.QueryResult<GetAgentQuery, GetAgentQueryVariables>;
export const GetFlexWorkerDocument = gql`
    query GetFlexWorker($email: String) {
        getFlexWorker(email: $email) {
            firstName
            lastName
            email
            phone
            dateOfBirth
            flexWorkerId
        }
    }
`;

/**
 * __useGetFlexWorkerQuery__
 *
 * To run a query within a React component, call `useGetFlexWorkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlexWorkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlexWorkerQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetFlexWorkerQuery(
    baseOptions?: Apollo.QueryHookOptions<GetFlexWorkerQuery, GetFlexWorkerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetFlexWorkerQuery, GetFlexWorkerQueryVariables>(
        GetFlexWorkerDocument,
        options
    );
}
export function useGetFlexWorkerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetFlexWorkerQuery, GetFlexWorkerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetFlexWorkerQuery, GetFlexWorkerQueryVariables>(
        GetFlexWorkerDocument,
        options
    );
}
export type GetFlexWorkerQueryHookResult = ReturnType<typeof useGetFlexWorkerQuery>;
export type GetFlexWorkerLazyQueryHookResult = ReturnType<typeof useGetFlexWorkerLazyQuery>;
export type GetFlexWorkerQueryResult = Apollo.QueryResult<
    GetFlexWorkerQuery,
    GetFlexWorkerQueryVariables
>;
export const GetWorkerDocument = gql`
    query GetWorker($accountKey: String, $email: String, $slug: String, $country: String) {
        getWorkers(accountKey: $accountKey, email: $email, slug: $slug, country: $country) {
            edges {
                node {
                    ...WorkerProfileFragment
                }
            }
        }
    }
    ${WorkerProfileFragmentFragmentDoc}
`;

/**
 * __useGetWorkerQuery__
 *
 * To run a query within a React component, call `useGetWorkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerQuery({
 *   variables: {
 *      accountKey: // value for 'accountKey'
 *      email: // value for 'email'
 *      slug: // value for 'slug'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetWorkerQuery(
    baseOptions?: Apollo.QueryHookOptions<GetWorkerQuery, GetWorkerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetWorkerQuery, GetWorkerQueryVariables>(GetWorkerDocument, options);
}
export function useGetWorkerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetWorkerQuery, GetWorkerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetWorkerQuery, GetWorkerQueryVariables>(GetWorkerDocument, options);
}
export type GetWorkerQueryHookResult = ReturnType<typeof useGetWorkerQuery>;
export type GetWorkerLazyQueryHookResult = ReturnType<typeof useGetWorkerLazyQuery>;
export type GetWorkerQueryResult = Apollo.QueryResult<GetWorkerQuery, GetWorkerQueryVariables>;
export const FindWorkersDocument = gql`
    query FindWorkers(
        $accountKey: String
        $email: String
        $slug: String
        $country: String
        $first: Int
        $offset: Int
    ) {
        getWorkers(
            accountKey: $accountKey
            email: $email
            slug: $slug
            country: $country
            first: $first
            offset: $offset
        ) {
            edges {
                node {
                    ...WorkerProfileFragment
                }
            }
            totalCount
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
    ${WorkerProfileFragmentFragmentDoc}
`;

/**
 * __useFindWorkersQuery__
 *
 * To run a query within a React component, call `useFindWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindWorkersQuery({
 *   variables: {
 *      accountKey: // value for 'accountKey'
 *      email: // value for 'email'
 *      slug: // value for 'slug'
 *      country: // value for 'country'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFindWorkersQuery(
    baseOptions?: Apollo.QueryHookOptions<FindWorkersQuery, FindWorkersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindWorkersQuery, FindWorkersQueryVariables>(
        FindWorkersDocument,
        options
    );
}
export function useFindWorkersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FindWorkersQuery, FindWorkersQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FindWorkersQuery, FindWorkersQueryVariables>(
        FindWorkersDocument,
        options
    );
}
export type FindWorkersQueryHookResult = ReturnType<typeof useFindWorkersQuery>;
export type FindWorkersLazyQueryHookResult = ReturnType<typeof useFindWorkersLazyQuery>;
export type FindWorkersQueryResult = Apollo.QueryResult<
    FindWorkersQuery,
    FindWorkersQueryVariables
>;
export const GetWorkerWithTasksAndPlacementsDocument = gql`
    query GetWorkerWithTasksAndPlacements(
        $accountKey: String
        $email: String
        $slug: String
        $country: String
    ) {
        getWorkers(accountKey: $accountKey, email: $email, slug: $slug, country: $country) {
            edges {
                node {
                    ...WorkerProfileFragment
                    activeTasks {
                        ...TaskFragment
                    }
                    activePlacements {
                        ...PlacementFragment
                    }
                    inactiveTasks {
                        ...RightToWorkTaskFragment
                    }
                }
            }
        }
    }
    ${WorkerProfileFragmentFragmentDoc}
    ${TaskFragmentFragmentDoc}
    ${PlacementFragmentFragmentDoc}
    ${RightToWorkTaskFragmentFragmentDoc}
`;

/**
 * __useGetWorkerWithTasksAndPlacementsQuery__
 *
 * To run a query within a React component, call `useGetWorkerWithTasksAndPlacementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerWithTasksAndPlacementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerWithTasksAndPlacementsQuery({
 *   variables: {
 *      accountKey: // value for 'accountKey'
 *      email: // value for 'email'
 *      slug: // value for 'slug'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetWorkerWithTasksAndPlacementsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetWorkerWithTasksAndPlacementsQuery,
        GetWorkerWithTasksAndPlacementsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetWorkerWithTasksAndPlacementsQuery,
        GetWorkerWithTasksAndPlacementsQueryVariables
    >(GetWorkerWithTasksAndPlacementsDocument, options);
}
export function useGetWorkerWithTasksAndPlacementsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetWorkerWithTasksAndPlacementsQuery,
        GetWorkerWithTasksAndPlacementsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetWorkerWithTasksAndPlacementsQuery,
        GetWorkerWithTasksAndPlacementsQueryVariables
    >(GetWorkerWithTasksAndPlacementsDocument, options);
}
export type GetWorkerWithTasksAndPlacementsQueryHookResult = ReturnType<
    typeof useGetWorkerWithTasksAndPlacementsQuery
>;
export type GetWorkerWithTasksAndPlacementsLazyQueryHookResult = ReturnType<
    typeof useGetWorkerWithTasksAndPlacementsLazyQuery
>;
export type GetWorkerWithTasksAndPlacementsQueryResult = Apollo.QueryResult<
    GetWorkerWithTasksAndPlacementsQuery,
    GetWorkerWithTasksAndPlacementsQueryVariables
>;
export const GetTasksDocument = gql`
    query GetTasks(
        $accountKey: String
        $email: String
        $workerSlug: String
        $slug: ID
        $orderBy: String
        $first: Int
        $offset: Int
    ) {
        getTasks(
            accountKey: $accountKey
            email: $email
            workerSlug: $workerSlug
            slug: $slug
            orderBy: $orderBy
            first: $first
            offset: $offset
        ) {
            edges {
                node {
                    ...TaskFragment
                }
            }
            totalCount
        }
    }
    ${TaskFragmentFragmentDoc}
`;

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *      accountKey: // value for 'accountKey'
 *      email: // value for 'email'
 *      workerSlug: // value for 'workerSlug'
 *      slug: // value for 'slug'
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTasksQuery(
    baseOptions?: Apollo.QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
}
export function useGetTasksLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetTasksQuery, GetTasksQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
}
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<typeof useGetTasksLazyQuery>;
export type GetTasksQueryResult = Apollo.QueryResult<GetTasksQuery, GetTasksQueryVariables>;
export const GetTasksWithFiltersDocument = gql`
    query GetTasksWithFilters(
        $accountKey: String
        $email: String
        $slug: ID
        $orderBy: String
        $createdDateGte: DateTime
        $lastModifiedDateGte: DateTime
        $first: Int
        $offset: Int
        $status: [String]
        $progressStatus: [String]
        $country: String
        $roleFlexId: String
        $clientFlexId: String
        $taskName: String
    ) {
        getTasks(
            accountKey: $accountKey
            email: $email
            slug: $slug
            orderBy: $orderBy
            createdDateGte: $createdDateGte
            lastModifiedDateGte: $lastModifiedDateGte
            first: $first
            offset: $offset
            status: $status
            progressStatus: $progressStatus
            country: $country
            roleFlexId: $roleFlexId
            clientFlexId: $clientFlexId
            taskName: $taskName
        ) {
            edges {
                node {
                    ...TaskWithWorkerAndPlacementFragment
                }
            }
            totalCount
        }
    }
    ${TaskWithWorkerAndPlacementFragmentFragmentDoc}
`;

/**
 * __useGetTasksWithFiltersQuery__
 *
 * To run a query within a React component, call `useGetTasksWithFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksWithFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksWithFiltersQuery({
 *   variables: {
 *      accountKey: // value for 'accountKey'
 *      email: // value for 'email'
 *      slug: // value for 'slug'
 *      orderBy: // value for 'orderBy'
 *      createdDateGte: // value for 'createdDateGte'
 *      lastModifiedDateGte: // value for 'lastModifiedDateGte'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      status: // value for 'status'
 *      progressStatus: // value for 'progressStatus'
 *      country: // value for 'country'
 *      roleFlexId: // value for 'roleFlexId'
 *      clientFlexId: // value for 'clientFlexId'
 *      taskName: // value for 'taskName'
 *   },
 * });
 */
export function useGetTasksWithFiltersQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetTasksWithFiltersQuery,
        GetTasksWithFiltersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetTasksWithFiltersQuery, GetTasksWithFiltersQueryVariables>(
        GetTasksWithFiltersDocument,
        options
    );
}
export function useGetTasksWithFiltersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetTasksWithFiltersQuery,
        GetTasksWithFiltersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetTasksWithFiltersQuery, GetTasksWithFiltersQueryVariables>(
        GetTasksWithFiltersDocument,
        options
    );
}
export type GetTasksWithFiltersQueryHookResult = ReturnType<typeof useGetTasksWithFiltersQuery>;
export type GetTasksWithFiltersLazyQueryHookResult = ReturnType<
    typeof useGetTasksWithFiltersLazyQuery
>;
export type GetTasksWithFiltersQueryResult = Apollo.QueryResult<
    GetTasksWithFiltersQuery,
    GetTasksWithFiltersQueryVariables
>;
export const FindReverifiableRtwTasksDocument = gql`
    query FindReverifiableRtwTasks(
        $type: [String] = ["RIGHT_TO_WORK"]
        $status: [String] = ["NOT_COMPLETED"]
        $completedCountGte: Int = 2
        $first: Int
        $after: String = ""
        $orderBy: String = "lastModified"
    ) {
        getTasks(
            type: $type
            status: $status
            completedCountGte: $completedCountGte
            first: $first
            after: $after
            orderBy: $orderBy
        ) {
            edges {
                node {
                    slug
                    type
                    completedCount
                    metadata {
                        ... on RightToWorkMetadataType {
                            worker {
                                slug
                                email
                                firstName
                                middleName
                                lastName
                                flexWorkerId
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useFindReverifiableRtwTasksQuery__
 *
 * To run a query within a React component, call `useFindReverifiableRtwTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindReverifiableRtwTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindReverifiableRtwTasksQuery({
 *   variables: {
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      completedCountGte: // value for 'completedCountGte'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFindReverifiableRtwTasksQuery(
    baseOptions?: Apollo.QueryHookOptions<
        FindReverifiableRtwTasksQuery,
        FindReverifiableRtwTasksQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FindReverifiableRtwTasksQuery, FindReverifiableRtwTasksQueryVariables>(
        FindReverifiableRtwTasksDocument,
        options
    );
}
export function useFindReverifiableRtwTasksLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        FindReverifiableRtwTasksQuery,
        FindReverifiableRtwTasksQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        FindReverifiableRtwTasksQuery,
        FindReverifiableRtwTasksQueryVariables
    >(FindReverifiableRtwTasksDocument, options);
}
export type FindReverifiableRtwTasksQueryHookResult = ReturnType<
    typeof useFindReverifiableRtwTasksQuery
>;
export type FindReverifiableRtwTasksLazyQueryHookResult = ReturnType<
    typeof useFindReverifiableRtwTasksLazyQuery
>;
export type FindReverifiableRtwTasksQueryResult = Apollo.QueryResult<
    FindReverifiableRtwTasksQuery,
    FindReverifiableRtwTasksQueryVariables
>;
export const GetUserRtwTaskDocument = gql`
    query GetUserRtwTask($type: [String] = ["RIGHT_TO_WORK"], $email: String) {
        getTasks(type: $type, email: $email, includeInactive: true, last: 2) {
            edges {
                node {
                    slug
                    accountKey
                    email
                    status
                    externalStatus
                    type
                    completedDate
                    processedDate
                    completedCount
                    redirectUrl
                    metadata {
                        ... on RightToWorkMetadataType {
                            activeChecks {
                                checkType
                                status
                            }
                            rightToWork {
                                slug
                                shareCode
                                ukCitizen
                                euCitizen
                                externalId
                                expirationDate
                                externalWorkerId
                                ilr
                                pss
                                fullLegalName
                                workingHourLimits {
                                    slug
                                    workingHourLimit
                                    startTermDate
                                    endTermDate
                                }
                            }
                            worker {
                                slug
                                email
                                dateOfBirth
                                firstName
                                middleName
                                lastName
                                flexWorkerId
                            }
                            workerGovernmentIds {
                                slug
                                idNumber
                                type
                                country
                                expiresOn
                                images {
                                    slug
                                    status
                                    imageUrl
                                    externalId
                                    externalIdType
                                    mimeType
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useGetUserRtwTaskQuery__
 *
 * To run a query within a React component, call `useGetUserRtwTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRtwTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRtwTaskQuery({
 *   variables: {
 *      type: // value for 'type'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserRtwTaskQuery(
    baseOptions?: Apollo.QueryHookOptions<GetUserRtwTaskQuery, GetUserRtwTaskQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUserRtwTaskQuery, GetUserRtwTaskQueryVariables>(
        GetUserRtwTaskDocument,
        options
    );
}
export function useGetUserRtwTaskLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetUserRtwTaskQuery, GetUserRtwTaskQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUserRtwTaskQuery, GetUserRtwTaskQueryVariables>(
        GetUserRtwTaskDocument,
        options
    );
}
export type GetUserRtwTaskQueryHookResult = ReturnType<typeof useGetUserRtwTaskQuery>;
export type GetUserRtwTaskLazyQueryHookResult = ReturnType<typeof useGetUserRtwTaskLazyQuery>;
export type GetUserRtwTaskQueryResult = Apollo.QueryResult<
    GetUserRtwTaskQuery,
    GetUserRtwTaskQueryVariables
>;
export const GetRightToWorkCredasProfileLinkDocument = gql`
    query GetRightToWorkCredasProfileLink($agentEmail: String!, $entityId: String!) {
        getRightToWorkCredasProfileLink(agentEmail: $agentEmail, entityId: $entityId) {
            url
        }
    }
`;

/**
 * __useGetRightToWorkCredasProfileLinkQuery__
 *
 * To run a query within a React component, call `useGetRightToWorkCredasProfileLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRightToWorkCredasProfileLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRightToWorkCredasProfileLinkQuery({
 *   variables: {
 *      agentEmail: // value for 'agentEmail'
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useGetRightToWorkCredasProfileLinkQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetRightToWorkCredasProfileLinkQuery,
        GetRightToWorkCredasProfileLinkQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetRightToWorkCredasProfileLinkQuery,
        GetRightToWorkCredasProfileLinkQueryVariables
    >(GetRightToWorkCredasProfileLinkDocument, options);
}
export function useGetRightToWorkCredasProfileLinkLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetRightToWorkCredasProfileLinkQuery,
        GetRightToWorkCredasProfileLinkQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetRightToWorkCredasProfileLinkQuery,
        GetRightToWorkCredasProfileLinkQueryVariables
    >(GetRightToWorkCredasProfileLinkDocument, options);
}
export type GetRightToWorkCredasProfileLinkQueryHookResult = ReturnType<
    typeof useGetRightToWorkCredasProfileLinkQuery
>;
export type GetRightToWorkCredasProfileLinkLazyQueryHookResult = ReturnType<
    typeof useGetRightToWorkCredasProfileLinkLazyQuery
>;
export type GetRightToWorkCredasProfileLinkQueryResult = Apollo.QueryResult<
    GetRightToWorkCredasProfileLinkQuery,
    GetRightToWorkCredasProfileLinkQueryVariables
>;
export const GetUserEligibilityDocument = gql`
    query GetUserEligibility($countryCode: String!) {
        getUserEligibility(countryCode: $countryCode) {
            eligibleTasks
            isVerified
        }
    }
`;

/**
 * __useGetUserEligibilityQuery__
 *
 * To run a query within a React component, call `useGetUserEligibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEligibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEligibilityQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useGetUserEligibilityQuery(
    baseOptions: Apollo.QueryHookOptions<GetUserEligibilityQuery, GetUserEligibilityQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUserEligibilityQuery, GetUserEligibilityQueryVariables>(
        GetUserEligibilityDocument,
        options
    );
}
export function useGetUserEligibilityLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetUserEligibilityQuery,
        GetUserEligibilityQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUserEligibilityQuery, GetUserEligibilityQueryVariables>(
        GetUserEligibilityDocument,
        options
    );
}
export type GetUserEligibilityQueryHookResult = ReturnType<typeof useGetUserEligibilityQuery>;
export type GetUserEligibilityLazyQueryHookResult = ReturnType<
    typeof useGetUserEligibilityLazyQuery
>;
export type GetUserEligibilityQueryResult = Apollo.QueryResult<
    GetUserEligibilityQuery,
    GetUserEligibilityQueryVariables
>;
export const DownloadDocumentDocument = gql`
    query DownloadDocument($slug: String!, $documentType: DownloadDocumentType!) {
        downloadDocument(slug: $slug, documentType: $documentType) {
            url
            mimeType
        }
    }
`;

/**
 * __useDownloadDocumentQuery__
 *
 * To run a query within a React component, call `useDownloadDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadDocumentQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      documentType: // value for 'documentType'
 *   },
 * });
 */
export function useDownloadDocumentQuery(
    baseOptions: Apollo.QueryHookOptions<DownloadDocumentQuery, DownloadDocumentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<DownloadDocumentQuery, DownloadDocumentQueryVariables>(
        DownloadDocumentDocument,
        options
    );
}
export function useDownloadDocumentLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<DownloadDocumentQuery, DownloadDocumentQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<DownloadDocumentQuery, DownloadDocumentQueryVariables>(
        DownloadDocumentDocument,
        options
    );
}
export type DownloadDocumentQueryHookResult = ReturnType<typeof useDownloadDocumentQuery>;
export type DownloadDocumentLazyQueryHookResult = ReturnType<typeof useDownloadDocumentLazyQuery>;
export type DownloadDocumentQueryResult = Apollo.QueryResult<
    DownloadDocumentQuery,
    DownloadDocumentQueryVariables
>;
export const FeatureFlagsDocument = gql`
    query FeatureFlags {
        featureFlags {
            name
            active
        }
    }
`;

/**
 * __useFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureFlagsQuery(
    baseOptions?: Apollo.QueryHookOptions<FeatureFlagsQuery, FeatureFlagsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<FeatureFlagsQuery, FeatureFlagsQueryVariables>(
        FeatureFlagsDocument,
        options
    );
}
export function useFeatureFlagsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<FeatureFlagsQuery, FeatureFlagsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<FeatureFlagsQuery, FeatureFlagsQueryVariables>(
        FeatureFlagsDocument,
        options
    );
}
export type FeatureFlagsQueryHookResult = ReturnType<typeof useFeatureFlagsQuery>;
export type FeatureFlagsLazyQueryHookResult = ReturnType<typeof useFeatureFlagsLazyQuery>;
export type FeatureFlagsQueryResult = Apollo.QueryResult<
    FeatureFlagsQuery,
    FeatureFlagsQueryVariables
>;
export const DownloadAllDocumentsDocument = gql`
    query DownloadAllDocuments($documents: [DocumentQueryInput!]!) {
        downloadAllDocuments(documents: $documents) {
            result {
                url
                slug
                templateType
                templateTitle
            }
            errors
        }
    }
`;

/**
 * __useDownloadAllDocumentsQuery__
 *
 * To run a query within a React component, call `useDownloadAllDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadAllDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadAllDocumentsQuery({
 *   variables: {
 *      documents: // value for 'documents'
 *   },
 * });
 */
export function useDownloadAllDocumentsQuery(
    baseOptions: Apollo.QueryHookOptions<
        DownloadAllDocumentsQuery,
        DownloadAllDocumentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<DownloadAllDocumentsQuery, DownloadAllDocumentsQueryVariables>(
        DownloadAllDocumentsDocument,
        options
    );
}
export function useDownloadAllDocumentsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DownloadAllDocumentsQuery,
        DownloadAllDocumentsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<DownloadAllDocumentsQuery, DownloadAllDocumentsQueryVariables>(
        DownloadAllDocumentsDocument,
        options
    );
}
export type DownloadAllDocumentsQueryHookResult = ReturnType<typeof useDownloadAllDocumentsQuery>;
export type DownloadAllDocumentsLazyQueryHookResult = ReturnType<
    typeof useDownloadAllDocumentsLazyQuery
>;
export type DownloadAllDocumentsQueryResult = Apollo.QueryResult<
    DownloadAllDocumentsQuery,
    DownloadAllDocumentsQueryVariables
>;
export const GetBackgroundCheckPackagesDocument = gql`
    query GetBackgroundCheckPackages {
        getBackgroundCheckPackages {
            edges {
                node {
                    package
                    title
                }
            }
        }
    }
`;

/**
 * __useGetBackgroundCheckPackagesQuery__
 *
 * To run a query within a React component, call `useGetBackgroundCheckPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBackgroundCheckPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBackgroundCheckPackagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBackgroundCheckPackagesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetBackgroundCheckPackagesQuery,
        GetBackgroundCheckPackagesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetBackgroundCheckPackagesQuery,
        GetBackgroundCheckPackagesQueryVariables
    >(GetBackgroundCheckPackagesDocument, options);
}
export function useGetBackgroundCheckPackagesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetBackgroundCheckPackagesQuery,
        GetBackgroundCheckPackagesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetBackgroundCheckPackagesQuery,
        GetBackgroundCheckPackagesQueryVariables
    >(GetBackgroundCheckPackagesDocument, options);
}
export type GetBackgroundCheckPackagesQueryHookResult = ReturnType<
    typeof useGetBackgroundCheckPackagesQuery
>;
export type GetBackgroundCheckPackagesLazyQueryHookResult = ReturnType<
    typeof useGetBackgroundCheckPackagesLazyQuery
>;
export type GetBackgroundCheckPackagesQueryResult = Apollo.QueryResult<
    GetBackgroundCheckPackagesQuery,
    GetBackgroundCheckPackagesQueryVariables
>;
export const GetClientsDocument = gql`
    query GetClients($name: String, $country: CountryCode, $flexId: ID, $first: Int, $last: Int) {
        getClients(name: $name, country: $country, flexId: $flexId, first: $first, last: $last) {
            edges {
                node {
                    country
                    flexId
                    id
                    name
                    onboardedId
                    slug
                }
            }
        }
    }
`;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      country: // value for 'country'
 *      flexId: // value for 'flexId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetClientsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetClientsQuery, GetClientsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
}
export function useGetClientsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(
        GetClientsDocument,
        options
    );
}
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<typeof useGetClientsLazyQuery>;
export type GetClientsQueryResult = Apollo.QueryResult<GetClientsQuery, GetClientsQueryVariables>;
export const GetRolesDocument = gql`
    query GetRoles($name: String, $country: CountryCode, $flexId: ID) {
        getRoles(name: $name, country: $country, flexId: $flexId) {
            edges {
                node {
                    country
                    name
                    flexId
                    onboardedId
                    slug
                    id
                }
            }
        }
    }
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      country: // value for 'country'
 *      flexId: // value for 'flexId'
 *   },
 * });
 */
export function useGetRolesQuery(
    baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetTaskNamesDocument = gql`
    query GetTaskNames {
        getTaskNames {
            name
            value
        }
    }
`;

/**
 * __useGetTaskNamesQuery__
 *
 * To run a query within a React component, call `useGetTaskNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTaskNamesQuery(
    baseOptions?: Apollo.QueryHookOptions<GetTaskNamesQuery, GetTaskNamesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetTaskNamesQuery, GetTaskNamesQueryVariables>(
        GetTaskNamesDocument,
        options
    );
}
export function useGetTaskNamesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetTaskNamesQuery, GetTaskNamesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetTaskNamesQuery, GetTaskNamesQueryVariables>(
        GetTaskNamesDocument,
        options
    );
}
export type GetTaskNamesQueryHookResult = ReturnType<typeof useGetTaskNamesQuery>;
export type GetTaskNamesLazyQueryHookResult = ReturnType<typeof useGetTaskNamesLazyQuery>;
export type GetTaskNamesQueryResult = Apollo.QueryResult<
    GetTaskNamesQuery,
    GetTaskNamesQueryVariables
>;
export const GetTaskStatusCountsDocument = gql`
    query GetTaskStatusCounts(
        $createdDateGte: DateTime
        $lastModifiedDateGte: DateTime
        $country: String
        $roleFlexId: String
        $clientFlexId: String
        $taskName: String
    ) {
        getTasks(
            createdDateGte: $createdDateGte
            lastModifiedDateGte: $lastModifiedDateGte
            country: $country
            roleFlexId: $roleFlexId
            clientFlexId: $clientFlexId
            taskName: $taskName
        ) {
            progressStatusCounts {
                agentDisplayStatus
                count
                progressStatus
            }
        }
    }
`;

/**
 * __useGetTaskStatusCountsQuery__
 *
 * To run a query within a React component, call `useGetTaskStatusCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskStatusCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskStatusCountsQuery({
 *   variables: {
 *      createdDateGte: // value for 'createdDateGte'
 *      lastModifiedDateGte: // value for 'lastModifiedDateGte'
 *      country: // value for 'country'
 *      roleFlexId: // value for 'roleFlexId'
 *      clientFlexId: // value for 'clientFlexId'
 *      taskName: // value for 'taskName'
 *   },
 * });
 */
export function useGetTaskStatusCountsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetTaskStatusCountsQuery,
        GetTaskStatusCountsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetTaskStatusCountsQuery, GetTaskStatusCountsQueryVariables>(
        GetTaskStatusCountsDocument,
        options
    );
}
export function useGetTaskStatusCountsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetTaskStatusCountsQuery,
        GetTaskStatusCountsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetTaskStatusCountsQuery, GetTaskStatusCountsQueryVariables>(
        GetTaskStatusCountsDocument,
        options
    );
}
export type GetTaskStatusCountsQueryHookResult = ReturnType<typeof useGetTaskStatusCountsQuery>;
export type GetTaskStatusCountsLazyQueryHookResult = ReturnType<
    typeof useGetTaskStatusCountsLazyQuery
>;
export type GetTaskStatusCountsQueryResult = Apollo.QueryResult<
    GetTaskStatusCountsQuery,
    GetTaskStatusCountsQueryVariables
>;
export const GetPlacementsDocument = gql`
    query GetPlacements(
        $offset: Int
        $first: Int
        $roleFlexId: Decimal
        $clientFlexId: Decimal
        $country: String
        $createdDateGte: DateTime
        $lastModifiedDateGte: DateTime
        $status: [String]
        $interviewedBy: String
        $flexOnboardingStatus: [String]
        $orderBy: String
        $includeEmpty: Boolean = false
    ) {
        getPlacements(
            offset: $offset
            first: $first
            roleFlexId: $roleFlexId
            clientFlexId: $clientFlexId
            country: $country
            createdDateGte: $createdDateGte
            lastModifiedDateGte: $lastModifiedDateGte
            status: $status
            interviewedBy: $interviewedBy
            flexOnboardingStatus: $flexOnboardingStatus
            orderBy: $orderBy
            includeEmpty: $includeEmpty
        ) {
            edges {
                node {
                    ...PlacementWithWorkerFragment
                }
            }
            totalCount
        }
    }
    ${PlacementWithWorkerFragmentFragmentDoc}
`;

/**
 * __useGetPlacementsQuery__
 *
 * To run a query within a React component, call `useGetPlacementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlacementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlacementsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *      roleFlexId: // value for 'roleFlexId'
 *      clientFlexId: // value for 'clientFlexId'
 *      country: // value for 'country'
 *      createdDateGte: // value for 'createdDateGte'
 *      lastModifiedDateGte: // value for 'lastModifiedDateGte'
 *      status: // value for 'status'
 *      interviewedBy: // value for 'interviewedBy'
 *      flexOnboardingStatus: // value for 'flexOnboardingStatus'
 *      orderBy: // value for 'orderBy'
 *      includeEmpty: // value for 'includeEmpty'
 *   },
 * });
 */
export function useGetPlacementsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetPlacementsQuery, GetPlacementsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPlacementsQuery, GetPlacementsQueryVariables>(
        GetPlacementsDocument,
        options
    );
}
export function useGetPlacementsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetPlacementsQuery, GetPlacementsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPlacementsQuery, GetPlacementsQueryVariables>(
        GetPlacementsDocument,
        options
    );
}
export type GetPlacementsQueryHookResult = ReturnType<typeof useGetPlacementsQuery>;
export type GetPlacementsLazyQueryHookResult = ReturnType<typeof useGetPlacementsLazyQuery>;
export type GetPlacementsQueryResult = Apollo.QueryResult<
    GetPlacementsQuery,
    GetPlacementsQueryVariables
>;
export const GetPlacementsStatusCountDocument = gql`
    query GetPlacementsStatusCount(
        $roleFlexId: Decimal
        $clientFlexId: Decimal
        $country: String
        $createdDateGte: DateTime
        $lastModifiedDateGte: DateTime
        $interviewedBy: String
        $includeEmpty: Boolean = false
    ) {
        getPlacements(
            roleFlexId: $roleFlexId
            clientFlexId: $clientFlexId
            country: $country
            createdDateGte: $createdDateGte
            lastModifiedDateGte: $lastModifiedDateGte
            interviewedBy: $interviewedBy
            includeEmpty: $includeEmpty
        ) {
            statusCounts {
                count
                status
                displayStatus
            }
            flexOnboardingStatusCounts {
                count
                flexOnboardingStatus
            }
        }
    }
`;

/**
 * __useGetPlacementsStatusCountQuery__
 *
 * To run a query within a React component, call `useGetPlacementsStatusCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlacementsStatusCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlacementsStatusCountQuery({
 *   variables: {
 *      roleFlexId: // value for 'roleFlexId'
 *      clientFlexId: // value for 'clientFlexId'
 *      country: // value for 'country'
 *      createdDateGte: // value for 'createdDateGte'
 *      lastModifiedDateGte: // value for 'lastModifiedDateGte'
 *      interviewedBy: // value for 'interviewedBy'
 *      includeEmpty: // value for 'includeEmpty'
 *   },
 * });
 */
export function useGetPlacementsStatusCountQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetPlacementsStatusCountQuery,
        GetPlacementsStatusCountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPlacementsStatusCountQuery, GetPlacementsStatusCountQueryVariables>(
        GetPlacementsStatusCountDocument,
        options
    );
}
export function useGetPlacementsStatusCountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetPlacementsStatusCountQuery,
        GetPlacementsStatusCountQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetPlacementsStatusCountQuery,
        GetPlacementsStatusCountQueryVariables
    >(GetPlacementsStatusCountDocument, options);
}
export type GetPlacementsStatusCountQueryHookResult = ReturnType<
    typeof useGetPlacementsStatusCountQuery
>;
export type GetPlacementsStatusCountLazyQueryHookResult = ReturnType<
    typeof useGetPlacementsStatusCountLazyQuery
>;
export type GetPlacementsStatusCountQueryResult = Apollo.QueryResult<
    GetPlacementsStatusCountQuery,
    GetPlacementsStatusCountQueryVariables
>;
/* eslint-enable */
