import React from 'react';

import OnboardingUrlLoader from 'client/pages/payroll/components/OnboardingUrlLoader';
import StatusTag from 'client/components/StatusTag';
import {
    RequiresAdditionalDocumentsPageContent,
    RequiresBgcPageContent,
    RequiresCjoPageContent,
    RequiresProfilePageContent,
} from 'client/components/RequiresTaskPageContent/RequiresTaskPageContent';
import { useTaskDisabledReason } from 'client/hooks/useTaskDisabledReason';

type UnableToBeWorkedProps = {
    task: ComplianceTask;
};

const UnableToBeWorked = ({ task }: UnableToBeWorkedProps): JSX.Element => {
    return (
        <>
            <StatusTag task={task} sx={{ mb: '1.5rem' }} />
            <p>Check back soon to start your pay onboarding process.</p>
        </>
    );
};

type SuccessProps = {
    task: ComplianceTask;
};

const Success = ({ task }: SuccessProps): JSX.Element => {
    return (
        <>
            <StatusTag task={task} sx={{ mb: '1.5rem' }} />
            <p>
                You've successfully completed your payroll task. Please complete the remaining
                onboarding tasks.
            </p>
        </>
    );
};

type PageContentProps = {
    task: ComplianceTask;
};

const PageContent = ({ task }: PageContentProps): JSX.Element => {
    const disabledReason = useTaskDisabledReason(task);
    switch (disabledReason) {
        case 'INCOMPLETE_PROFILE':
            return <RequiresProfilePageContent task={task} taskName="payroll setup" />;
        case 'INCOMPLETE_CJO':
            return <RequiresCjoPageContent task={task} taskName="payroll setup" />;
        case 'INCOMPLETE_BGC':
            return <RequiresBgcPageContent task={task} taskName="payroll setup" />;
        case 'INCOMPLETE_DOCUMENTS':
            return <RequiresAdditionalDocumentsPageContent task={task} taskName="Payroll setup" />;
        default:
            switch (task.status) {
                case 'NOT_COMPLETED':
                    return <OnboardingUrlLoader />;
                case 'DISABLED':
                case 'NEEDS_REVIEW':
                    return <UnableToBeWorked task={task} />;
                case 'APPROVED':
                    return <Success task={task} />;
                default:
                    throw Error(`Invalid status for payroll: ${task.status}`);
            }
    }
};

export default PageContent;
