import dayjs from 'client/utils/dayjs';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { AnyObjectSchema, date, object, string } from 'yup';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { SupportedCountryCode } from 'client/contexts/LocaleContext';
import { validatePhoneNumber, validatePhoneNumberLength } from 'client/utils/form';

const phoneNumberValidation = string()
    .trim()
    .test('is-valid-phone-length', 'Phone number must be valid length.', (value, context) => {
        const { parent } = context;
        return validatePhoneNumberLength(parent);
    })
    .test('is-valid-phone-number', 'Must enter a valid phone number.', (value, context) => {
        const { parent } = context;
        return validatePhoneNumber(parent);
    });

const baseValidationSchema = object().shape({
    firstName: string().trim().required('Legal first name is required.'),
    middleName: string()
        .trim()
        .test(
            'isEmpty',
            'Legal middle name is required.',
            (val) => !isEmpty(val) === !isUndefined(val?.length)
        ),
    lastName: string().trim().required('Legal last name is required.'),
    phoneCountry: string(),
    country: string(),
    phoneNumber: phoneNumberValidation,
    dateOfBirth: date()
        .required('Date of birth is required.')
        .max(dayjs().subtract(18, 'year').toDate(), 'You must be at least 18 years old'),
});

export const ukProfileValidationSchema = baseValidationSchema.concat(
    object().shape({
        postalCode: string()
            .required('Postcode is required')
            .matches(
                /^(([A-Za-z][0-9]{1,2})|(([A-Za-z][A-HJ-Ya-hj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-HJ-Ya-hj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2}$/,
                'Postcode is not valid for Great Britain'
            ),
        address1: string().trim().required('Address is required.'),
        city: string().trim().required('City is required.'),
        title: string().trim().required('Title is required.'),
        legalGender: string().trim().required('Gender is required.'),
    })
);

export const usProfileValidationSchema = baseValidationSchema.concat(
    object().shape({
        address1: string().trim().required('Address is required.'),
        address2: string().trim().max(25, 'Apt. Number cannot be more than 25 characters.'),
        city: string().trim().required('City is required.'),
        state: string().trim().required('State is required.'),
        postalCode: string()
            .trim()
            .matches(/^\d+$/, 'Postal code should contain digits only.')
            .max(5, 'Postal code must be at most 5 digits.')
            .min(5, 'Postal code must be at least 5 digits.')
            .required('Postal code is required and must be 5 digits.'),
        ssn: string()
            .trim()
            .when('ssnAppliedFor', {
                is: (ssnAppliedFor: string[]) => !ssnAppliedFor.includes('true'),
                then: string()
                    .trim()
                    .max(9, 'Social security number must be at most 9 digits.')
                    .min(9, 'Social security number must be at least 9 digits.')
                    .matches(/^\d+$/, 'Social security number should contain digits only.')
                    .required(
                        'Social security number is required and must be 9 numerical characters.'
                    ),
            }),
    })
);

export const getValidationSchema = (country: string): AnyObjectSchema =>
    country === SupportedCountryCode.US ? usProfileValidationSchema : ukProfileValidationSchema;

export interface ProfileFormValues {
    firstName: string;
    lastName: string;
    middleName: string;
    noMiddleName: string[];
    dateOfBirth: string | null;
    address1: string;
    address2: string;
    state: string;
    city: string;
    postalCode: string;
    country: string;
    suggestedAddress1: string | null;
    suggestedAddress2: string | null;
    suggestedState: string | null;
    suggestedCity: string | null;
    suggestedPostalCode: string | null;
    gender: string;
    phoneCountry: string;
    phoneNumber: string;
    lat: number | null;
    lon: number | null;
    optOut48hrs: string[];
    ssnAppliedFor: string[];
    ssn: string;
    title: string;
    legalGender: string;
    manualAddressEdit: boolean;
}

const getDefaultValues = (country: string): ProfileFormValues => ({
    firstName: '',
    lastName: '',
    middleName: '',
    noMiddleName: [],
    dateOfBirth: null,
    address1: '',
    address2: '',
    state: '',
    city: '',
    postalCode: '',
    country: country,
    gender: '',
    phoneCountry: country,
    phoneNumber: '',
    lat: 0.0,
    lon: 0.0,
    optOut48hrs: [],
    ssnAppliedFor: [],
    ssn: '',
    title: '',
    legalGender: '',
    manualAddressEdit: false,
    suggestedAddress1: null,
    suggestedAddress2: null,
    suggestedState: null,
    suggestedCity: null,
    suggestedPostalCode: null,
});

export const toProfileFormValues = (worker: ComplianceWorker): Partial<ProfileFormValues> => {
    const {
        firstName,
        middleName,
        lastName,
        gender,
        workerAddress,
        phone,
        dateOfBirth,
        optOut48hrs,
        title,
        legalGender,
    } = worker;
    const { address1, address2, state, city, postalCode, country, lat, lon } = workerAddress || {};

    const result: Partial<ProfileFormValues> = {
        firstName,
        lastName,
        noMiddleName: ['false'],
        dateOfBirth,
        title,
    };

    if (optOut48hrs) {
        result.optOut48hrs = ['true'];
    } else {
        result.optOut48hrs = [];
    }

    if (middleName) {
        result.middleName = middleName;
        result.noMiddleName = ['false'];
    }
    if (gender && gender !== 'BLANK') {
        result.gender = gender;
    }

    if (legalGender && legalGender !== 'BLANK') {
        result.legalGender = legalGender;
    }

    if (phone) {
        const phoneNumber = parsePhoneNumber(phone);
        result.phoneCountry = phoneNumber.country;
        result.phoneNumber = phoneNumber.formatNational();
    }

    const addressFields = { address1, address2, state, city, postalCode, country, lat, lon };
    Object.entries(addressFields).forEach(([field, value]) => {
        if (value) {
            // @ts-ignore: these fields are ok
            result[field] = value;
        }
    });

    return result;
};

export const toUpdateWorkerInput = (
    accountKey: string,
    values: ProfileFormValues
): UpdateWorkerInput => {
    return {
        accountKey,
        firstName: values.firstName,
        middleName: values.middleName === 'N/A' ? '' : values.middleName,
        lastName: values.lastName,
        gender: (values.gender || 'BLANK') as WorkerGender,
        dateOfBirth: values.dateOfBirth,
        phone: values.phoneNumber
            ? parsePhoneNumber(values.phoneNumber, values.phoneCountry as CountryCode).number
            : undefined,
        address: {
            address1: values.address1,
            address2: values.address2,
            state: values.state,
            city: values.city,
            postalCode:
                values.country === SupportedCountryCode.GB
                    ? values.postalCode.toUpperCase()
                    : values.postalCode,
            country: values.country,
            lat: values.lat,
            lon: values.lon,
        },
        country: values.country,
        optOut48hrs: values.optOut48hrs.includes('true'),
        governmentIds: values.ssn
            ? [
                  {
                      country: values.country,
                      type: 'SSN',
                      idNumber: values.ssn,
                  },
              ]
            : undefined,
        title: values.title,
        legalGender: (values.legalGender || 'BLANK') as WorkerLegalGender,
    };
};

export const getInitialValues = (worker: ComplianceWorker, country: string): ProfileFormValues => {
    const initial = toProfileFormValues(worker);
    return { ...getDefaultValues(country), ...initial };
};
