import React from 'react';
import { Box, Flex, Heading } from '@indeed/ifl-components';
import { CheckCircle, ChevronRight } from '@indeed/ifl-icons';
import { Link } from 'react-router-dom';

type DocumentItemProps = {
    title: string;
    to: string;
    documentComplete: boolean;
};

const DocumentItem = ({ title, to, documentComplete }: DocumentItemProps): JSX.Element => {
    const linkProps = {
        to,
    };

    return (
        <Link {...linkProps}>
            <Flex
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '.5rem',
                    mx: '-1rem',
                    padding: '1rem 1rem',
                    borderBottom: ({ colors }) => `1px solid ${colors.neutral['300']}`,
                }}
            >
                <Box sx={{ height: '1.5rem' }}>
                    <CheckCircle
                        color={documentComplete ? 'success.default' : 'neutral.500'}
                        aria-label={documentComplete ? 'Completed' : 'Not completed'}
                    />
                </Box>
                <Box sx={{ flex: '1' }}>
                    <Heading level={3} sx={{ m: 0, fontWeight: 'normal' }}>
                        {title}
                    </Heading>
                </Box>
                <Box sx={{ height: '1.5rem', alignSelf: 'center' }}>
                    <ChevronRight color="neutral.700" aria-hidden />
                </Box>
            </Flex>
        </Link>
    );
};

export default DocumentItem;
