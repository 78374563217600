import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import BackButton from 'client/components/BackButton';
import ComplianceContainer from 'client/components/ComplianceContainer';
import DocumentDisplayPage from 'client/pages/documentdisplay';
import DocumentListPage from 'client/pages/documentlist';
import Page from 'client/components/Page';
import { LoadingPage } from 'client/components/Loading';
import {
    RequiresCjoPageContent,
    RequiresProfilePageContent,
} from 'client/components/RequiresTaskPageContent/RequiresTaskPageContent';
import { findTaskByType, getMatchingWorkerDocument, getTaskDocuments } from 'client/utils/task';
import { indexLocation } from 'client/utils/redirect';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useTaskDisabledReason } from 'client/hooks/useTaskDisabledReason';
import { useTasks } from 'client/contexts/TasksContext';
import { useToast } from 'client/contexts/ToastContext';
import { useUpdateTaskStatusMutation } from 'client/hooks/graphql';

const MediaRequirementsPage = (): JSX.Element => {
    const history = useHistory();
    const { completed, loading, tasks, updateTask } = useTasks();
    const { path } = useRouteMatch();
    const { pushToast } = useToast();
    const mediaTasks = tasks.filter((t) => t.type === 'MEDIA_REQUIREMENTS');
    // Media Tasks cannot be disabled themselves so just checking for Profile/CJO with the first task in the list
    const disabledReason = useTaskDisabledReason(mediaTasks[0]);
    const [callUpdateTaskStatusMutation, { loading: updateTaskStatusLoading }] =
        useUpdateTaskStatusMutation();

    /**
     * Iterates through all of the tasks and completes the task when there are no more templates without a corresponding
     * worker document. Done here rather in the document display page so that the user can "resubmit" the task
     * completion in case of an error by refreshing.
     */
    useEffect(() => {
        mediaTasks.forEach((task) => {
            if (task && !disabledReason && task.status !== 'COMPLETED') {
                const { templates, workerDocuments } = getTaskDocuments(task);
                if (
                    !templates.find(
                        (template) => !getMatchingWorkerDocument(template, workerDocuments)
                    )
                ) {
                    callUpdateTaskStatusMutation({
                        variables: { slug: task.slug, status: 'COMPLETED' },
                    })
                        .then(({ data: taskData }) => {
                            const taskResult = taskData?.updateTask?.result;
                            if (taskResult) {
                                updateTask(taskResult);
                                history.push(indexLocation);
                            } else {
                                reportErrorToThirdPartyTools(
                                    new Error(
                                        `Could not retrieve result from updateTask mutation given input: taskSlug:${task.slug}, taskStatus:COMPLETED`
                                    ),
                                    'mediaRequirementsPage',
                                    'checkAndPossiblyCompleteTask'
                                );
                                pushToast(
                                    'There was an error while retrieving the response from submission, but the data may have saved. Refresh the page and try again.',
                                    { status: 'warning' }
                                );
                            }
                        })
                        .catch((apolloError) => {
                            reportErrorToThirdPartyTools(
                                apolloError,
                                'mediaRequirementsPage',
                                'checkAndPossiblyCompleteTask'
                            );
                            pushToast(
                                'There was an error while attempting to submit the information, please try again.',
                                { status: 'critical' }
                            );
                        });
                }
            }
        });
    }, [mediaTasks]);

    if (!completed || loading || updateTaskStatusLoading) {
        return <LoadingPage />;
    }

    if (!mediaTasks || mediaTasks.length === 0) {
        return <Redirect to="/" />;
    }

    if (disabledReason === 'INCOMPLETE_PROFILE') {
        return findTaskByType(tasks, 'PROFILE') ? (
            <Page title="Additional trainings">
                <ComplianceContainer>
                    <BackButton />
                    <RequiresProfilePageContent
                        heading="Additional trainings"
                        task={mediaTasks[0]}
                        taskName="additional trainings"
                    />
                </ComplianceContainer>
            </Page>
        ) : (
            <Redirect to="/" />
        );
    } else if (disabledReason === 'INCOMPLETE_CJO') {
        return findTaskByType(tasks, 'CONDITIONAL_JOB_OFFER') ? (
            <Page title="Additional trainings">
                <ComplianceContainer>
                    <BackButton />
                    <RequiresCjoPageContent
                        heading="Additional trainings"
                        task={mediaTasks[0]}
                        taskName="additional trainings"
                    />
                </ComplianceContainer>
            </Page>
        ) : (
            <Redirect to="/" />
        );
    }

    return (
        <Page title="Additional trainings">
            <ComplianceContainer>
                <Switch>
                    <Route path={`${path}/document/:templateSlug`}>
                        <DocumentDisplayPage baseUrl={path} />
                    </Route>
                    <Route path={`${path}`}>
                        <DocumentListPage tasks={mediaTasks} pageTitle="Additional trainings" />
                    </Route>
                    <Route path="*" exact>
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </ComplianceContainer>
        </Page>
    );
};

export default MediaRequirementsPage;
